<template>
    <div class="my-3">
        <span class="isolate inline-flex rounded-md shadow-sm w-full m-auto mb-10 justify-between gap-4">
            <button :disabled="step <= 0" @click="step > 0 ? step-- : null" type="button"
                class="font-bold text-sm relative inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:bg-blue-400 hover:ring-blue-400 focus:z-10">
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                <span>Précédent</span>
            </button>
            <button :disabled="step < 0" @click="step >= 0 ? skipAnimation() : null" type="button"
                class="font-bold text-sm relative -ml-px inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:ring-blue-400 hover:bg-blue-400  focus:z-10">
                <span>Passer l'animation</span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
        </span>

        <div class="text-center text-lg sm:text-3xl" style="font-family: 'Simple Plan'">
            <div id="animated-text"></div>
        </div>

        <TransitionRoot appear :show="showFormStep1" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto flex gap-2 mt-3 justify-center">
            <div class="relative w-full sm:max-w-sm">
                <label for="email" class="block text-sm font-medium leading-6 text-white">Email</label>
                <input type="email" name="email" id="email"
                    class="block w-full bg-transparent rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="mathieu@equipagesolidaire.fr" v-model="email" />
                <div v-if="errors.email"
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mt-6">
                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
                <p v-if="errors.email" class="absolute mt-2 ml-1 text-sm text-red-600" id="username-error">
                    {{ errors.email }}
                </p>
            </div>

            <button type="button" @click="startStepShowStep2"
                class="self-end inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Valider
            </button>
        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep2" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto flex gap-2 mt-3 justify-center">
            <div class="relative w-full sm:max-w-sm">
                <label for="phone" class="block text-sm font-medium leading-6 text-white">Telephone</label>
                <input type="tel" name="phone" id="phone"
                    class="block w-full bg-transparent rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="0707070707" v-model="phone" />
                <div v-if="errors.phone"
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mt-6">
                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
                <p v-if="errors.phone" class="absolute mt-2 ml-1 text-sm text-red-600" id="username-error">
                    {{ errors.phone }}
                </p>
            </div>

            <button type="button" @click="startStepShowStep3"
                class="self-end inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Valider
            </button>
        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep3" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto flex gap-2 mt-3 justify-center">
            <div class="relative w-full sm:max-w-sm">
                <label for="birthDate" class="block text-sm font-medium leading-6 text-white">Date de naissance</label>
                <input type="text" name="birthDate" id="birthDate"
                    class="block w-full bg-transparent rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="10/12/1998" v-model="birthDate" />
                <div v-if="errors.birthDate"
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mt-6">
                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
                <p v-if="errors.birthDate" class="absolute mt-2 ml-1 text-sm text-red-600" id="username-error">
                    {{ errors.birthDate }}
                </p>
            </div>

            <button type="button" @click="startStepShowStep4"
                class="self-end inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Valider
            </button>
        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep4" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto flex gap-2 mt-3 justify-center">
            <RadioGroup v-model="newsletter">
                <RadioGroupLabel class="sr-only">Apply Type</RadioGroupLabel>
                <div class="space-y-2">
                    <RadioGroupOption @click="validateForm" as="template" v-for="type in ['OUI', 'NON']" :key="type"
                        :value="type === 'OUI'" v-slot="{ active, checked }">
                        <div :class="[
                active
                    ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300'
                    : '',
                checked ? 'bg-blue-600 text-white ' : 'bg-white ',
            ]" class="relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none">
                            <div class="w-full items-center justify-between">
                                <div class="items-center">
                                    <div class="text-sm">
                                        <RadioGroupLabel as="p"
                                            :class="[checked ? 'text-white' : 'text-gray-900', 'text-center m-auto']"
                                            class="font-medium">
                                            {{ type }}
                                        </RadioGroupLabel>
                                        <RadioGroupDescription as="p" v-if="type === 'OUI'"
                                            :class="['text-center', checked ? 'text-white' : 'text-gray-900']">
                                            Merci pour ton soutien, promis, nous ne t'enverrons pas trop de message ❤️
                                        </RadioGroupDescription>
                                        <RadioGroupDescription as="p" v-if="type === 'NON'"
                                            :class="['text-center', checked ? 'text-white' : 'text-gray-900']">
                                            Dommage, promis, on ne t'en voudra pas &#x1F622;
                                        </RadioGroupDescription>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </TransitionRoot>
    </div>
</template>

<script setup lang="ts">
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, TransitionRoot } from '@headlessui/vue';
import { onMounted, ref, watch, type Ref } from 'vue';
import { CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon, ExclamationCircleIcon } from '@heroicons/vue/16/solid';
import { yupSchemas } from "@/schemas";
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core';
import GraphemeSplitter from "grapheme-splitter";
import { useUserForm } from '@/stores/userForm';

const dataForm = defineModel<{
    email: string;
    phone: string;
    birthDate: number; //TODO
    newsletter: boolean;
} | any>();

dataForm.value = {}

const props = defineProps({
    onFormFinish: {
        type: Function,
    }
})

const showFormStep1 = ref(false);
const showFormStep2 = ref(false);
const showFormStep3 = ref(false);
const showFormStep4 = ref(false);

const errors = ref({
    email: '',
    phone: '',
    birthDate: '',
})

const email = ref(dataForm.value?.email);
const phone = ref(dataForm.value?.phone);
const birthDate = ref(dataForm.value?.birthDate);
const newsletter = ref(!!dataForm.value?.newsletter);
const typewriter = ref();

watch(() => email.value, () => errors.value.email = '')
watch(() => phone.value, () => errors.value.phone = '')
watch(() => birthDate.value, () => errors.value.birthDate = '')

const step = ref(-1);
const phrase = ref<string>();
let refToShow: Ref<Boolean>;

function skipAnimation() {
    refToShow.value = true;
}

watch(() => step.value, (newValue, oldValue) => {
    showFormStep1.value = false;
    showFormStep2.value = false;
    showFormStep3.value = false;
    showFormStep4.value = false;

    switch (newValue) {
        case 0:
            refToShow = showFormStep1;
            phrase.value = '<br />Sur quel email pouvons-nous te contacter et envoyer les notifications ? &#x1F4E7;'
            break;
        case 1:
            refToShow = showFormStep2;
            phrase.value = `<div><div />Nous la gardons précieusement &#x1F48E;<br/> <br/>Nous avons maintenant besoin de ton numéro de téléphone pour permettre à nos bénévoles de te contacter en cas de besoin &#x1F4DE;`
            break;
        case 2:
            refToShow = showFormStep3;
            phrase.value = `<div><div />Top ! Quelle est ta date de naissance ? &#x1F476;`
            break;
        case 3:
            refToShow = showFormStep4;
            phrase.value = `<div><div />Merci ! Souhaites-tu t'abonner à notre newsletter, cela te permettra d'être au courant des dernières nouveautés, mais aussi de nous aider lors de nos appels à projet qui nécessite un vote ! ❤️`
            break;
        default:
            phrase.value = ''
            break;
    }

    ((newValue > 0 || (newValue === 0 && oldValue >= 1)) ? typewriter.value.deleteAll(-1000) : typewriter.value)
        .typeString(phrase.value)
        .callFunction(() => {
            refToShow.value = true
        })
        .start();
})

const stringSplitter = (string: string) => {
    const splitter = new GraphemeSplitter();
    return splitter.splitGraphemes(string);
};

onMounted(() => {

    typewriter.value = new Typewriter('#animated-text', { delay: 25, stringSplitter });

    typewriter.value.typeString('<div><div />Nous avons besoin d\'en savoir plus sur toi pour rejoindre notre communauté &#x1F525;<br/>')
        .pauseFor(300)
        .callFunction(() => {
            step.value++
        })
        .start();

});

async function startStepShowStep2() {
    try {
        await yupSchemas.email.validate(email.value)
    } catch (err: any) {
        errors.value.email = err.errors[0]
        return;
    }

    dataForm.value!.email = email.value;

    showFormStep1.value = false;

    step.value++;
}

async function startStepShowStep3() {
    try {
        await yupSchemas.phone.validate(phone.value)
    } catch (err: any) {
        errors.value.phone = err.errors[0]
        return;
    }

    dataForm.value!.phone = phone.value;

    showFormStep2.value = false;

    step.value++;
}

async function startStepShowStep4() {
    try {
        await yupSchemas.birthDate.validate(birthDate.value)
    } catch (err: any) {
        errors.value.birthDate = err.errors[0]
        return;
    }

    dataForm.value!.birthDate = birthDate.value;

    showFormStep3.value = false;

    step.value++;
}

async function validateForm() {
    dataForm.value!.newsletter = newsletter.value;

    showFormStep3.value = false;

    const store = useUserForm();

    store.updateMoreInfosAboutYouForm(dataForm.value);

    if (props.onFormFinish)
        props.onFormFinish()
}

</script>