import biography from './biography'
import birthDate from './birthDate'
import email from './email'
import firstName from './firstName'
import gender from './gender'
import lastName from './lastName'
import password from './password'
import phone from './phone'
import pseudo from './pseudo'
import schoolName from './schoolName'

export const yupSchemas = {
  firstName,
  lastName,
  gender,
  password,
  pseudo,
  schoolName,
  email,
  phone,
  biography,
  birthDate
}

export default {}
