<template>
    <div class="my-3">
        <span class="isolate inline-flex rounded-md shadow-sm w-full m-auto mb-10 justify-between gap-4">
            <button :disabled="step <= 0" @click="step > 0 ? step-- : null" type="button"
                class="font-bold text-sm relative inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:bg-blue-400 hover:ring-blue-400 focus:z-10">
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                <span>Précédent</span>
            </button>
            <button :disabled="step < 0" @click="step >= 0 ? skipAnimation() : null" type="button"
                class="font-bold text-sm relative -ml-px inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:ring-blue-400 hover:bg-blue-400  focus:z-10">
                <span>Passer l'animation</span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
        </span>

        <div class="text-center text-lg sm:text-3xl" style="font-family: 'Simple Plan'">
            <div id="animated-text"></div>
        </div>


        <TransitionRoot appear :show="showFormStep1" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto flex gap-2 mt-3 justify-center">
            <div class="w-full text-center">
                <label for="bio" class="block text-sm font-medium leading-6 text-white">Biographie</label>
                <div class="mt-2">
                    <textarea rows="4" name="bio" id="bio" v-model="biography"
                        class="m-auto max-w-md block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
                </div>

                <p v-if="errors.biography" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
                    {{ errors.biography }}
                </p>

                <button type="button" @click="startStepShowStep2"
                    class="w-full max-w-md mt-3 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    <CheckCircleIcon class="h-5 w-5 ml-auto" aria-hidden="true" />
                    <span class="text-center mr-auto">Valider</span>
                </button>
            </div>
        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep2" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto flex-row gap-2 mt-3 justify-center">

            <input v-if="!fileAvatar" v-on:change="handleFileUpload($event)" type="file" name="small-file-input"
                id="small-file-input" class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none 
            file:bg-gray-50 file:border-0
            file:me-4
            file:py-2 file:px-4
            ">

            <p v-if="errors.avatar" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
                {{ errors.avatar }}
            </p>

            <div v-if="fileAvatar" class="mb-2 mt-3 flex justify-between items-center">
                <div class="flex items-center gap-x-3">
                    <div>
                        <p class="text-sm font-medium text-gray-800 dark:text-white">{{ fileAvatar?.name }}</p>
                        <p class="text-xs text-gray-500 dark:text-gray-500">{{ (fileAvatar?.size / 1000).toFixed(0) }}
                            KB</p>
                    </div>
                </div>

            </div>

            <div v-if="fileAvatar" class="flex items-center gap-x-3 whitespace-nowrap">
                <div class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
                    role="progressbar" aria-valuenow="1" aria-valuemin="0" aria-valuemax="100">
                    <div class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
                        :style="`width: ${uploadPercentage}%`"></div>
                </div>
                <div class="w-6 text-end">
                    <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
                </div>
            </div>

        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep3" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-auto max-w-md m-auto flex-row gap-2 mt-3 justify-center">
            <RadioGroup v-model="imageRight">
                <RadioGroupLabel class="sr-only">Image right</RadioGroupLabel>
                <div class="space-y-2">
                    <RadioGroupOption @click="startStepShowStep4" as="template" v-for="type in ['OUI', 'NON']"
                        :key="type" :value="type === 'OUI'" v-slot="{ active, checked }">
                        <div :class="[
                active
                    ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300'
                    : '',
                checked ? 'bg-blue-600 text-white ' : 'bg-white ',
            ]" class="relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none">
                            <div class="w-full items-center justify-between">
                                <div class="items-center">
                                    <div class="text-sm">
                                        <RadioGroupLabel as="p"
                                            :class="[checked ? 'text-white' : 'text-gray-900', 'text-center m-auto']"
                                            class="font-medium">
                                            {{ type }}
                                        </RadioGroupLabel>
                                        <RadioGroupDescription as="p" v-if="type === 'OUI'"
                                            :class="['text-center', checked ? 'text-white' : 'text-gray-900']">
                                            Tente ta chance de devenir une star !
                                        </RadioGroupDescription>
                                        <RadioGroupDescription as="p" v-if="type === 'NON'"
                                            :class="['text-center', checked ? 'text-white' : 'text-gray-900']">
                                            Dommage, ta carrière potentielle de star s'arette ici
                                        </RadioGroupDescription>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep4" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-auto max-w-md m-auto flex-row gap-2 mt-3 justify-center">
            <RadioGroup v-model="wantToBeStaff">
                <RadioGroupLabel class="sr-only">Want to be staff</RadioGroupLabel>
                <div class="space-y-2">
                    <RadioGroupOption @click="startStepShowStep5" as="template" v-for="type in ['OUI', 'NON']"
                        :key="type" :value="type === 'OUI'" v-slot="{ active, checked }">
                        <div :class="[
                active
                    ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300'
                    : '',
                checked ? 'bg-blue-600 text-white ' : 'bg-white ',
            ]" class="relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none">
                            <div class="w-full items-center justify-between">
                                <div class="items-center">
                                    <div class="text-sm">
                                        <RadioGroupLabel as="p"
                                            :class="[checked ? 'text-white' : 'text-gray-900', 'text-center m-auto']"
                                            class="font-medium">
                                            {{ type }}
                                        </RadioGroupLabel>
                                        <RadioGroupDescription as="p" v-if="type === 'OUI'"
                                            :class="['text-center', checked ? 'text-white' : 'text-gray-900']">
                                            Nous te recontacterons si nous avons besoin de nouveau adhérant &#x1F4AA;
                                        </RadioGroupDescription>
                                        <RadioGroupDescription as="p" v-if="type === 'NON'"
                                            :class="['text-center', checked ? 'text-white' : 'text-gray-900']">
                                            Aucun problème, merci pour le temps que tu accordes déja &#x1F4AA;
                                        </RadioGroupDescription>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </TransitionRoot>


        <TransitionRoot appear :show="showFormStep5" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-auto max-w-lg m-auto flex gap-2 mt-3 justify-center">
            <Listbox v-model="meansOfTransportation">
                <div class="w-full mt-1 relative">
                    <ListboxButton
                        class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
                        <span class="block truncate text-black">
                            {{ meansOfTransportation?.name || 'Choisisez un moyen de transport'
                            }}</span>
                        <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                    </ListboxButton>
                    <p v-if="errors.meansOfTransportation" class="absolute mt-2 ml-1 text-sm text-red-600"
                        id="meansof-error">
                        {{ errors.meansOfTransportation }}
                    </p>

                    <transition leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100"
                        leave-to-class="opacity-0">
                        <ListboxOptions
                            class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                            <ListboxOption v-slot="{ active, selected }"
                                v-for="meansOfTransportation in meansOfTransportations" :key="meansOfTransportation.key"
                                :value="meansOfTransportation">
                                <li :class="[
                active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-10 pr-4',
            ]">
                                    <span :class="[
                selected ? 'font-medium' : 'font-normal',
                'block truncate',
            ]">{{ meansOfTransportation.name }}
                                    </span>
                                    <span v-if="selected"
                                        class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600">
                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                    </span>
                                </li>
                            </ListboxOption>
                        </ListboxOptions>
                    </transition>
                </div>
            </Listbox>

            <button type="button" @click="validateForm"
                class="my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Valider
            </button>
        </TransitionRoot>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, type Ref } from 'vue';
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, TransitionRoot } from '@headlessui/vue';
import { CheckCircleIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpDownIcon } from '@heroicons/vue/16/solid';

import axios from "axios";
import { yupSchemas } from '@/schemas';
import GraphemeSplitter from "grapheme-splitter";
import { useUserForm } from '@/stores/userForm';

const props = defineProps({
    onFormFinish: {
        type: Function,
    }
})

const dataForm = defineModel<{
    biography: string;
    avatar: string;
    imageRight: boolean;
    wantToBeStaff: boolean;
    meansOfTransportation: string;
} | any>();
dataForm.value = {}

const typewriter = ref();

const showFormStep1 = ref(false);
const showFormStep2 = ref(false);
const showFormStep3 = ref(false);
const showFormStep4 = ref(false);
const showFormStep5 = ref(false);

const biography = ref(dataForm.value?.biography);
const avatar = ref(dataForm.value?.avatar);
const imageRight = ref(!!dataForm.value?.imageRight);
const wantToBeStaff = ref(!!dataForm.value?.wantToBeStaff);
const meansOfTransportation = ref(dataForm.value?.meansOfTransportation);

const fileAvatar = ref();
const uploadPercentage = ref(0);

const meansOfTransportations = [
    {
        key: "car",
        name: 'Voiture'
    },
    {
        key: "bike",
        name: 'Vélo'
    },
    {
        key: "trotinette",
        name: 'Trotinette'
    },
    {
        key: "scooter",
        name: 'Scooter'
    },
    {
        key: "bus",
        name: 'Bus/Metro'
    },
]

watch(() => biography.value, () => errors.value.biography = '')
watch(() => meansOfTransportation.value, () => errors.value.meansOfTransportation = '')

const errors = ref({
    biography: '',
    avatar: '',
    meansOfTransportation: '',
})

const step = ref(0);
const phrase = ref<string>();
let refToShow: Ref<Boolean>;

function skipAnimation() {
    refToShow.value = true;
}

watch(() => step.value, (newValue, oldValue) => {
    showFormStep1.value = false;
    showFormStep2.value = false;
    showFormStep3.value = false;
    showFormStep4.value = false;
    showFormStep5.value = false;

    switch (newValue) {
        case 1:
            refToShow = showFormStep2;
            phrase.value = `<div><div />Maintenant que nous en savons un peu plus sur toi, il est temps de te choisir un avatar &#x1F933;`
            break;
        case 2:
            refToShow = showFormStep3;
            phrase.value = `<div><div />Jolie ! Nous autorises-tu à utiliser ton image lors de collecte, livraison ou autres événements pour nos réseaux sociaux ? &#x2B50;`
            break;
        case 3:
            refToShow = showFormStep4;
            phrase.value = `<div><div />C'est noté ! Souhaites-tu postuler pour rejoindre l'équipage solidaire en tant que bénévole adhérent et nous aider dans d'autres missions ?`
            break;
        case 4:
            refToShow = showFormStep5;
            phrase.value = `<div><div />C'est noté ! Avec quel moyen de transport utilises-tu le plus souvent pour tes livraisons ?`
            break;
        default:
            phrase.value = ''
            break;
    }

    ((newValue > 0 || (newValue === 0 && oldValue >= 1)) ? typewriter.value.deleteAll(-1000) : typewriter.value)
        .typeString(phrase.value)
        .callFunction(() => {
            refToShow.value = true
        })
        .start();
})

const stringSplitter = (string: string) => {
    const splitter = new GraphemeSplitter();
    return splitter.splitGraphemes(string);
};

onMounted(() => {
    typewriter.value = new Typewriter('#animated-text', { delay: 30, stringSplitter });

    typewriter.value.deleteAll(-1000).typeString('<div><div />Nous allons maintenant créer ton profil de bénévole 🎉<br/> <br/>En commençant par une petite bio que tu souhaites partager à la communauté &#x1F525;')
        .callFunction(() => {
            showFormStep1.value = true;
        })
        .start();
})

async function startStepShowStep2() {
    try {
        await yupSchemas.biography.validate(biography.value)
    } catch (err: any) {
        errors.value.biography = err.errors[0]
        return;
    }

    dataForm.value!.biography = biography.value;

    showFormStep1.value = false;

    step.value++;
}

async function startStepShowStep3() {
    dataForm.value!.avatar = avatar.value;

    showFormStep2.value = false;

    step.value++;
}

async function startStepShowStep4() {
    dataForm.value!.imageRight = imageRight.value;

    showFormStep3.value = false;

    step.value++;
}

async function startStepShowStep5() {
    dataForm.value!.wantToBeStaff = wantToBeStaff.value;

    showFormStep4.value = false;

    step.value++;
}

function handleFileUpload(event: any) {
    fileAvatar.value = event.target.files[0]

    if (event.target.files[0]) {
        errors.value.avatar = ''
        submitAvatar()
    }
}

function submitAvatar() {
    let formData = new FormData();

    formData.append('document', fileAvatar.value);
    formData.append('type', "avatar");

    axios.post(import.meta.env.VITE_APP_BASE_URL + '/documents/upload',
        formData,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (progressEvent: any) {
                uploadPercentage.value = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            }
        }
    ).then((response) => {
        avatar.value = response.data.uuid
        showFormStep2.value = false

        startStepShowStep3()
    }).catch((error) => {
        fileAvatar.value = null
        errors.value.avatar = error.response?.data?.message || error.message
    });
}

function validateForm() {
    if (!meansOfTransportation.value) {
        errors.value.meansOfTransportation = 'Merci de choisir un moyen de transport';
        return;
    }

    dataForm.value!.meansOfTransportation = meansOfTransportation.value?.key

    showFormStep5.value = false;

    const store = useUserForm();

    store.updateYourProfileForm(dataForm.value);

    if (props.onFormFinish)
        props.onFormFinish()
}
</script>