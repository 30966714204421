<template>
    <div class="my-3">
        <span class="isolate inline-flex rounded-md shadow-sm w-full m-auto mb-10 justify-between gap-4">
            <button :disabled="step <= 0" @click="step > 0 ? step-- : null" type="button"
                class="font-bold text-sm relative inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:bg-blue-400 hover:ring-blue-400 focus:z-10">
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                <span>Précédent</span>
            </button>
            <button :disabled="step < 0" @click="step >= 0 ? skipAnimation() : null" type="button"
                class="font-bold text-sm relative -ml-px inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:ring-blue-400 hover:bg-blue-400  focus:z-10">
                <span>Passer l'animation</span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
        </span>


        <div class="text-center text-lg sm:text-3xl" style="font-family: 'Simple Plan'">
            <div id="animated-text"></div>
        </div>

        <TransitionRoot appear :show="showFormStep1" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100"
            leave-to="opacity-0">
            <div class="mx-2 mt-5 text-lg sm:text-xl font-bold w-full">
                <p style="font-family: 'Simple Plan'">Comment t'appelles tu ?</p>

                <div class="gap-2 w-full mt-3">
                    <div class="m-auto w-full sm:max-w-sm">
                        <label for="firstName" class="block text-sm font-medium leading-6 text-white">Prenom</label>
                        <input type="text" name="firstName" id="firstName"
                            class="block w-full bg-transparent rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Mathieu" v-model="firstName" />
                        <div v-if="errors.firstName"
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mt-6">
                            <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>
                        <p v-if="errors.firstName" class="absolute mt-2 ml-1 text-sm text-red-600" id="firstName-error">
                            {{ errors.firstName }}
                        </p>
                    </div>

                    <div class="m-auto w-full sm:max-w-sm mt-2">
                        <label for="lastName" class="block text-sm font-medium leading-6 text-white">Nom</label>
                        <input type="text" name="lastName" id="lastName"
                            class="block bg-transparent w-full rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            placeholder="Vigne" v-model="lastName" />

                        <div v-if="errors.lastName"
                            class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mt-6">
                            <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                        </div>
                        <p v-if="errors.lastName" class="absolute mt-2 text-sm text-red-600" id="firstName-error">
                            {{ errors.lastName }}
                        </p>
                    </div>

                    <div class="flex">
                        <button type="button" @click="startStepShowStep2"
                            class="w-full justify-center mx-auto max-w-md mt-2 h-full inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                            <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                            Valider
                        </button>
                    </div>
                </div>
            </div>
        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep2" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100"
            leave-to="opacity-0">
            <div class="mx-2 mt-5 text-lg sm:text-xl font-bold" style="font-family: Avenir-Book">
                <div>
                    <p style="font-family: 'Simple Plan'">Quel est ton genre ?</p>
                </div>

                <div class="flex gap-2">
                    <Combobox class="w-full sm:max-w-sm" v-model="gender">
                        <div class="relative mt-1">
                            <div
                                class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm">
                                <ComboboxInput
                                    class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 border-gray-600"
                                    :displayValue="(gender: any) => gender.name"
                                    @change="queryGender = $event.target.value" />
                                <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </ComboboxButton>
                            </div>
                            <p v-if="errors.gender" class="absolute mt-2 text-sm text-red-600" id="gender-error">
                                {{ errors.gender }}
                            </p>
                            <TransitionRoot leave="transition ease-in duration-100" leaveFrom="opacity-100"
                                leaveTo="opacity-0" @after-leave="queryGender = ''">
                                <ComboboxOptions
                                    class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
                                    <div v-if="filteredGender.length === 0 && queryGender !== ''"
                                        class="relative cursor-default select-none px-4 py-2 text-gray-700">
                                        Nothing found.
                                    </div>

                                    <ComboboxOption v-for="gender in filteredGender" as="template" :key="gender.id"
                                        :value="gender" v-slot="{ selected, active }">
                                        <li class="relative cursor-default select-none py-2 pl-10 pr-4" :class="{
                'bg-blue-600 text-white': active,
                'text-gray-900': !active,
            }">
                                            <span class="block truncate"
                                                :class="{ 'font-medium': selected, 'font-normal': !selected }">
                                                {{ gender.name }}
                                            </span>
                                            <span v-if="selected"
                                                class="absolute inset-y-0 left-0 flex items-center pl-3"
                                                :class="{ 'text-white': active, 'text-blue-600': !active }">
                                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                                            </span>
                                        </li>
                                    </ComboboxOption>
                                </ComboboxOptions>
                            </TransitionRoot>
                        </div>
                    </Combobox>

                    <button type="button" @click="startStepShowStep3"
                        class="inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                        Valider
                    </button>
                </div>
            </div>
        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep3" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto flex gap-2 mt-3 justify-center">

            <div class="relative w-full sm:max-w-sm">
                <label for="username" class="block text-sm font-medium leading-6 text-white">Pseudo</label>
                <input type="text" name="username" id="username"
                    class="block w-full bg-transparent rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="marentdev" v-model="username" />
                <div v-if="errors.username"
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mt-6">
                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
                <p v-if="errors.username" class="absolute mt-2 ml-1 text-sm text-red-600" id="username-error">
                    {{ errors.username }}
                </p>
            </div>

            <button type="button" @click="startStepShowStep4"
                class="self-end inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Valider
            </button>
        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep4" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto flex gap-2 mt-3 justify-center">
            <RadioGroup v-model="applyType">
                <RadioGroupLabel class="sr-only">Apply Type</RadioGroupLabel>
                <div class="space-y-2">
                    <RadioGroupOption @click="startStepShowStep5" as="template" v-for="type in ['DELIVERER', 'STUDENT']"
                        :key="type" :value="type" v-slot="{ active, checked }">
                        <div :class="[
                active
                    ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300'
                    : '',
                checked ? 'bg-blue-600 text-white ' : 'bg-white ',
            ]" class="relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none">
                            <div class="w-full items-center justify-between">
                                <div class="items-center">
                                    <div class="text-sm">
                                        <RadioGroupLabel as="p"
                                            :class="[checked ? 'text-white' : 'text-gray-900', 'text-center m-auto']"
                                            class="font-medium">
                                            {{ type === 'DELIVERER' ? 'BENEVOLE' : '' }}
                                            {{ type === 'STUDENT' ? 'ETUDIANT' : '' }}
                                        </RadioGroupLabel>
                                        <RadioGroupDescription as="p" v-if="type === 'DELIVERER'"
                                            class="text-center text-gray-900">
                                            Si tu souhaites venir en aide aux étudiants
                                        </RadioGroupDescription>
                                        <RadioGroupDescription as="p" v-if="type === 'STUDENT'"
                                            class="text-center text-gray-900">
                                            Si tu souhaites recevoir ton kit
                                        </RadioGroupDescription>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep5" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto flex gap-2 mt-3 justify-center">
            <div class="relative w-full sm:max-w-sm">
                <label for="password" class="block text-sm font-medium leading-6 text-white">Mot de passe</label>
                <input type="password" name="password" id="password"
                    class="block w-full bg-transparent rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="••••••••" v-model="password" />
                <div v-if="errors.password"
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mt-6">
                    <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
                <p v-if="errors.password" class="absolute mt-2 ml-1 text-sm text-red-600" id="password-error">
                    {{ errors.password }}
                </p>
            </div>

            <button type="button" @click="validateForm"
                class="self-end inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
                Valider
            </button>
        </TransitionRoot>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch, type Ref } from 'vue';
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core';
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions, RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, TransitionRoot } from '@headlessui/vue';
import { CheckCircleIcon, CheckIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpDownIcon, ExclamationCircleIcon } from '@heroicons/vue/16/solid';
import { yupSchemas } from "@/schemas"
import GraphemeSplitter from "grapheme-splitter";
import { useUserForm } from '@/stores/userForm';
import axios from 'axios';

const dataForm = defineModel<{
    firstName: string;
    lastName: string;
    gender: number;
    username: string;
    password: string;
    applyType: 'DELIVERER' | 'STUDENT'
} | any>();

const props = defineProps({
    onFormFinish: {
        type: Function,
    }
})

dataForm.value = {}

const showFormStep1 = ref(false);
const showFormStep2 = ref(false);
const showFormStep3 = ref(false);
const showFormStep4 = ref(false);
const showFormStep5 = ref(false);
const typewriter = ref();
const errors = ref({
    firstName: '',
    lastName: '',
    gender: '',
    username: '',
    password: '',
    applyType: '',
})

const firstName = ref(dataForm.value?.firstName);
const lastName = ref(dataForm.value?.lastName);
const gender = ref(dataForm.value?.gender);
const username = ref(dataForm.value?.username);
const password = ref(dataForm.value?.password);
const applyType = ref<'DELIVERER' | 'STUDENT'>(dataForm.value?.applyType);

watch(() => firstName.value, () => errors.value.firstName = '')
watch(() => lastName.value, () => errors.value.lastName = '')
watch(() => gender.value, () => errors.value.gender = '')
watch(() => username.value, () => errors.value.username = '')
watch(() => password.value, () => errors.value.password = '')
watch(() => applyType.value, () => errors.value.applyType = '')

const step = ref(-1);
const phrase = ref<string>();
let refToShow: Ref<Boolean>;

function skipAnimation() {
    refToShow.value = true;
}

watch(() => step.value, (newValue, oldValue) => {
    showFormStep1.value = false;
    showFormStep2.value = false;
    showFormStep3.value = false;
    showFormStep4.value = false;
    showFormStep5.value = false;

    switch (newValue) {
        case 0:
            refToShow = showFormStep1;
            phrase.value = '<br/><br/>Nous allons te guider tout au long du processus pour rejoindre la communauté DELIVRAIDE ❤️'
            break;
        case 1:
            refToShow = showFormStep2;
            phrase.value = `Bienvenue <b>${firstName.value} ${lastName.value}</b> !`
            break;
        case 2:
            refToShow = showFormStep3;
            phrase.value = `<div><div />Coool ! Maintenant, nous aurions besoin que tu te choisisses un pseudo qui sera ton identité dans la communauté &#x1F525;`
            break;
        case 3:
            refToShow = showFormStep4;
            phrase.value = `Tu te présenteras donc sous le pseudo <b>${username.value}</b> &#x1F47D;<br /><br/>Quelle communauté souhaites-tu rejoindre ? &#x1FA82;`
            break;
        case 4:
            refToShow = showFormStep5;
            phrase.value = applyType.value === 'DELIVERER' ? `<div><div />Merci de donner de ton temps pour les étudiants &#x1F4AA;<br /><br />Choisis un mot de passe pour sécuriser ton compte &#x1F510;` : `<div><div />Nous ferons notre maximum pour te venir en aide &#x1F4AA;<br /><br />Choisis un mot de passe pour sécuriser ton compte &#x1F510;`
            break;
        default:
            phrase.value = ''
            break;
    }

    ((newValue > 0 || (newValue === 0 && oldValue >= 1)) ? typewriter.value.deleteAll(-1000) : typewriter.value)
        .typeString(phrase.value)
        .callFunction(() => {
            refToShow.value = true
        })
        .start();
})

const stringSplitter = (string: string) => {
    const splitter = new GraphemeSplitter();
    return splitter.splitGraphemes(string);
};

onMounted(() => {

    typewriter.value = new Typewriter('#animated-text', { delay: 25, stringSplitter });

    typewriter.value.typeString('Hey, nous sommes heureux de te rencontrer !')
        .pauseFor(300)
        .callFunction(() => step.value++)
        .start();
});

let genders = ref([
    { id: 1, name: 'Homme' },
    { id: 2, name: 'Femme' },
    { id: 3, name: 'Non-binaire' },
    { id: 4, name: 'Genre fluide' },
    { id: 5, name: 'Bigenre' },
    { id: 6, name: 'Agender' },
    { id: 7, name: 'Androgyne' },
    { id: 8, name: 'Transgenre' },
    { id: 9, name: 'Pangenre' },
    { id: 10, name: 'Demifille' },
    { id: 11, name: 'Demigarçon' },
    { id: 12, name: 'Genre non conforme' },
    { id: 13, name: 'Deux-esprits' },
    { id: 14, name: 'Hijra' },
    { id: 15, name: 'Neutrois' },
    { id: 16, name: 'Queer' },
    { id: 17, name: 'En questionnement de genre' },
    { id: 18, name: 'Variant de genre' },
    { id: 19, name: 'Femme au centre' },
    { id: 20, name: 'Butch' },
    { id: 21, name: 'Drag king' },
    { id: 22, name: 'Drag queen' },
    { id: 23, name: 'Genreflux' },
    { id: 24, name: 'Intergenre' },
    { id: 25, name: 'Trigenre' },
    { id: 26, name: 'Polygenre' },
    { id: 27, name: 'Amorgender' },
    { id: 28, name: 'Maverique' },
    { id: 29, name: 'Xenogenre' },
    { id: 30, name: 'Surgenre' },
    { id: 31, name: 'Libragenre' },
    { id: 99, name: 'Non Specifie' }
])
let queryGender = ref('')
let filteredGender = computed(() =>
    queryGender.value === ''
        ? genders.value
        : genders.value.filter((person) =>
            person.name
                .toLowerCase()
                .replace(/\s+/g, '')
                .includes(queryGender.value.toLowerCase().replace(/\s+/g, ''))
        )
)

async function startStepShowStep2() {
    try {
        await yupSchemas.firstName.validate(firstName.value)
    } catch (err: any) {
        errors.value.firstName = err.errors[0]
        return;
    }

    try {
        await yupSchemas.lastName.validate(lastName.value)
    } catch (err: any) {
        errors.value.lastName = err.errors[0]
        return;
    }

    dataForm.value!.firstName = firstName.value;
    dataForm.value!.lastName = lastName.value;

    showFormStep1.value = false;

    step.value++;
}

async function startStepShowStep3() {
    try {
        await yupSchemas.gender.validate(gender.value?.id)
    } catch (err: any) {
        errors.value.gender = err.errors[0]
        return;
    }

    dataForm.value!.gender = parseInt(gender.value.id, 10);

    showFormStep2.value = false;

    step.value++;
}

async function startStepShowStep4() {
    try {
        await yupSchemas.pseudo.validate(username.value)
    } catch (err: any) {
        errors.value.username = err.errors[0]
        return;
    }

    try {
        const usernameResponse = await axios.post(import.meta.env.VITE_APP_BASE_URL + '/user/username', { username: username.value });

        if (usernameResponse.data?.exist) {
            errors.value.username = 'Sorry, this username is already taken'
            return;
        }
    } catch (error) {
        errors.value.username = 'Failed to verify username'
        return;
    }

    dataForm.value!.username = username.value;

    showFormStep3.value = false;

    step.value++;
}

function startStepShowStep5() {
    dataForm.value!.applyType = applyType.value;

    showFormStep4.value = false;

    step.value++;
}

async function validateForm() {
    try {
        await yupSchemas.password.validate(password.value)
    } catch (err: any) {
        errors.value.password = err.errors[0]

        return;
    }

    dataForm.value!.password = password.value;

    showFormStep5.value = false;

    const store = useUserForm();

    store.updateDelivererOrStudentForm(dataForm.value);

    if (props.onFormFinish)
        props.onFormFinish()
}
</script>