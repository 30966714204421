<template>
  <div class="my-3">
    <span
      class="isolate inline-flex rounded-md shadow-sm w-full m-auto mb-10 justify-between gap-4"
    >
      <button
        :disabled="step <= 0"
        @click="step > 0 ? step-- : null"
        type="button"
        class="font-bold text-sm relative inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:bg-blue-400 hover:ring-blue-400 focus:z-10"
      >
        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
        <span>Précédent</span>
      </button>
      <button
        :disabled="step < 0"
        @click="step >= 0 ? skipAnimation() : null"
        type="button"
        class="font-bold text-sm relative -ml-px inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:ring-blue-400 hover:bg-blue-400 focus:z-10"
      >
        <span>Passer l'animation</span>
        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </span>

    <div class="text-center text-lg sm:text-3xl" style="font-family: 'Simple Plan'">
      <div id="animated-text"></div>
    </div>

    <TransitionRoot
      appear
      :show="showFormStep1"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <input
        v-if="!scolarship"
        v-on:change="handleFileUpload($event, 0)"
        type="file"
        name="small-file-input"
        id="small-file-input"
        class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
      />

      <p v-if="errors.scolarship" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
        {{ errors.scolarship }}
      </p>

      <div v-if="scolarship" class="mb-2 mt-3 flex justify-between items-center">
        <div class="flex items-center gap-x-3">
          <div>
            <p class="text-sm font-medium text-gray-800 dark:text-white">{{ scolarship?.name }}</p>
            <p class="text-xs text-gray-500 dark:text-gray-500">
              {{ (scolarship?.size / 1000).toFixed(0) }} KB
            </p>
          </div>
        </div>
      </div>

      <div v-if="scolarship" class="flex items-center gap-x-3 whitespace-nowrap">
        <div
          class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
          role="progressbar"
          aria-valuenow="1"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
            :style="`width: ${uploadPercentage}%`"
          ></div>
        </div>
        <div class="w-6 text-end">
          <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
        </div>
      </div>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep2"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <div class="max-w-md mx-auto">
        <div class="relative mt-2 rounded-md shadow-sm mb-3">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-300 sm:text-sm">€</span>
          </div>
          <input
            type="text"
            v-model="dataForm.revenueAmount"
            name="price"
            id="price"
            class="block text-white bg-transparent w-full rounded-md border-0 py-1.5 pl-7 pr-12 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="0.00"
            aria-describedby="price-currency"
          />
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span class="text-gray-300 sm:text-sm" id="price-currency">EUR</span>
          </div>
        </div>

        <button
          type="button"
          v-if="dataForm.revenueAmount == 0"
          @click="startShowNextStep()"
          class="mt-3 w-full sm:col-span-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <CheckCircleIcon class="ml-auto h-5 w-5" aria-hidden="true" />
          <span class="text-center mr-auto">Valider</span>
        </button>

        <input
          v-if="!revenueProof && dataForm.revenueAmount > 0"
          v-on:change="handleFileUpload($event, 1)"
          type="file"
          name="small-file-input"
          id="small-file-input"
          class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
        />

        <p v-if="errors.revenueProof" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
          {{ errors.revenueProof }}
        </p>

        <div v-if="revenueProof" class="mb-2 mt-3 flex justify-between items-center">
          <div class="flex items-center gap-x-3">
            <div>
              <p class="text-sm font-medium text-gray-800 dark:text-white">
                {{ revenueProof?.name }}
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-500">
                {{ (revenueProof?.size / 1000).toFixed(0) }} KB
              </p>
            </div>
          </div>
        </div>

        <div v-if="revenueProof" class="flex items-center gap-x-3 whitespace-nowrap">
          <div
            class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
            role="progressbar"
            aria-valuenow="1"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
              :style="`width: ${uploadPercentage}%`"
            ></div>
          </div>
          <div class="w-6 text-end">
            <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
          </div>
        </div>
      </div>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep3"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <div class="max-w-md mx-auto">
        <div class="relative mt-2 rounded-md shadow-sm mb-3">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-300 sm:text-sm">€</span>
          </div>
          <input
            type="text"
            v-model="dataForm.aplAmount"
            name="price"
            id="price"
            class="block text-white bg-transparent w-full rounded-md border-0 py-1.5 pl-7 pr-12 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="0.00"
            aria-describedby="price-currency"
          />
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span class="text-gray-300 sm:text-sm" id="price-currency">EUR</span>
          </div>
        </div>

        <button
          type="button"
          v-if="dataForm.aplAmount == 0"
          @click="startShowNextStep"
          class="mt-3 w-full sm:col-span-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <CheckCircleIcon class="ml-auto h-5 w-5" aria-hidden="true" />
          <span class="text-center mr-auto">Valider</span>
        </button>

        <input
          v-if="!aplProof && dataForm.aplAmount > 0"
          v-on:change="handleFileUpload($event, 3)"
          type="file"
          name="small-file-input"
          id="small-file-input"
          class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
        />

        <p v-if="errors.aplProof" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
          {{ errors.aplProof }}
        </p>

        <div v-if="aplProof" class="mb-2 mt-3 flex justify-between items-center">
          <div class="flex items-center gap-x-3">
            <div>
              <p class="text-sm font-medium text-gray-800 dark:text-white">{{ aplProof?.name }}</p>
              <p class="text-xs text-gray-500 dark:text-gray-500">
                {{ (aplProof?.size / 1000).toFixed(0) }} KB
              </p>
            </div>
          </div>
        </div>

        <div v-if="aplProof" class="flex items-center gap-x-3 whitespace-nowrap">
          <div
            class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
            role="progressbar"
            aria-valuenow="1"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
              :style="`width: ${uploadPercentage}%`"
            ></div>
          </div>
          <div class="w-6 text-end">
            <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
          </div>
        </div>
      </div>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep4"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <div class="max-w-md mx-auto">
        <Listbox class="mb-3" v-model="dataForm.bourseGrade">
          <div class="mt-1 relative">
            <ListboxButton
              class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            >
              <span class="block truncate text-black">{{
                bourseGrades[dataForm.bourseGrade + 1]?.name || 'Choisisez votre echelon'
              }}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-slot="{ active, selected }"
                  v-for="echelon in bourseGrades"
                  :key="echelon.value"
                  :value="echelon.value"
                >
                  <li
                    :class="[
                      active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-10 pr-4'
                    ]"
                  >
                    <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']"
                      >{{ echelon.name }}
                    </span>
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>

        <input
          v-if="!bourseProof && dataForm.bourseGrade >= 0"
          v-on:change="handleFileUpload($event, 4)"
          type="file"
          name="small-file-input"
          id="small-file-input"
          class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
        />

        <p v-if="errors.bourseProof" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
          {{ errors.bourseProof }}
        </p>

        <div v-if="bourseProof" class="mb-2 mt-3 flex justify-between items-center">
          <div class="flex items-center gap-x-3">
            <div>
              <p class="text-sm font-medium text-gray-800 dark:text-white">
                {{ bourseProof?.name }}
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-500">
                {{ (bourseProof?.size / 1000).toFixed(0) }} KB
              </p>
            </div>
          </div>
        </div>

        <div v-if="bourseProof" class="flex items-center gap-x-3 whitespace-nowrap">
          <div
            class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
            role="progressbar"
            aria-valuenow="1"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
              :style="`width: ${uploadPercentage}%`"
            ></div>
          </div>
          <div class="w-6 text-end">
            <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
          </div>
        </div>

        <button
          type="button"
          v-if="!Number.isNaN(dataForm.bourseGrade) || dataForm.bourseGrade === -1"
          @click="
            () => {
              if (dataForm.bourseGrade === -1) startShowNextStep()
              else if (dataForm.bourseGrade >= 0 && dataForm.bourseProof) startShowNextStep()
            }
          "
          class="mt-3 w-full mb-2 sm:col-span-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <CheckCircleIcon class="ml-auto h-5 w-5" aria-hidden="true" />
          <span class="text-center mr-auto">Valider</span>
        </button>
      </div>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep5"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <div class="max-w-md mx-auto">
        <Listbox class="mb-3" v-model="dataForm.rentType">
          <div class="mt-1 relative">
            <ListboxButton
              class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            >
              <span class="block truncate text-black">{{
                homeTypes[dataForm.rentType]?.name || 'Choisisez votre type de logement'
              }}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-slot="{ active, selected }"
                  v-for="type in homeTypes"
                  :key="type.value"
                  :value="type.value"
                >
                  <li
                    :class="[
                      active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-10 pr-4'
                    ]"
                  >
                    <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']"
                      >{{ type.name }}
                    </span>
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>

        <div class="relative z-0 mt-2 rounded-md shadow-sm mb-3">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-300 sm:text-sm">€</span>
          </div>
          <input
            type="text"
            v-model="dataForm.rentAmount"
            name="price"
            id="price"
            class="block text-white bg-transparent w-full rounded-md border-0 py-1.5 pl-7 pr-12 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="0.00"
            aria-describedby="price-currency"
          />
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span class="text-gray-300 sm:text-sm" id="price-currency">EUR</span>
          </div>
        </div>

        <input
          v-if="!rentReceipt"
          v-on:change="handleFileUpload($event, 5)"
          type="file"
          name="small-file-input"
          id="small-file-input"
          class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
        />

        <p v-if="errors.rentReceipt" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
          {{ errors.rentReceipt }}
        </p>

        <div v-if="rentReceipt" class="mb-2 mt-3 flex justify-between items-center">
          <div class="flex items-center gap-x-3">
            <div>
              <p class="text-sm font-medium text-gray-800 dark:text-white">
                {{ rentReceipt?.name }}
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-500">
                {{ (rentReceipt?.size / 1000).toFixed(0) }} KB
              </p>
            </div>
          </div>
        </div>

        <div v-if="rentReceipt" class="flex items-center gap-x-3 whitespace-nowrap">
          <div
            class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
            role="progressbar"
            aria-valuenow="1"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
              :style="`width: ${uploadPercentage}%`"
            ></div>
          </div>
          <div class="w-6 text-end">
            <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
          </div>
        </div>

        <button
          type="button"
          @click="startShowNextStep"
          v-if="dataForm.rentReceipt && dataForm.rentType >= 0 && dataForm.rentAmount >= 0"
          class="mt-3 w-full sm:col-span-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <CheckCircleIcon class="ml-auto h-5 w-5" aria-hidden="true" />
          <span class="text-center mr-auto">Valider</span>
        </button>
      </div>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep6"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <TabGroup>
        <TabList class="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          <Tab as="template" key="1" v-slot="{ selected }">
            <button
              :class="[
                'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                'ring-white/60  focus:outline-none',
                selected
                  ? 'bg-white text-blue-700 shadow'
                  : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
              ]"
            >
              Water
            </button>
          </Tab>
          <Tab as="template" key="2" v-slot="{ selected }">
            <button
              :class="[
                'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                'ring-white/60 focus:outline-none',
                selected
                  ? 'bg-white text-blue-700 shadow'
                  : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
              ]"
            >
              Electricity
            </button>
          </Tab>
          <Tab as="template" key="3" v-slot="{ selected }">
            <button
              :class="[
                'w-full rounded-lg py-2.5 text-sm font-medium leading-5',
                'ring-white/60 focus:outline-none',
                selected
                  ? 'bg-white text-blue-700 shadow'
                  : 'text-blue-100 hover:bg-white/[0.12] hover:text-white'
              ]"
            >
              Gaz
            </button>
          </Tab>
        </TabList>

        <TabPanels class="mt-2">
          <TabPanel
            key="1"
            :class="[
              'rounded-xl bg-transparent p-3',
              'ring-white/60 ring-offset-2 focus:outline-none '
            ]"
          >
            <div class="relative z-0 mt-2 rounded-md shadow-sm mb-3">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-300 sm:text-sm">€</span>
              </div>
              <input
                type="text"
                v-model="dataForm.waterAmount"
                name="price"
                id="price"
                class="block text-white bg-transparent w-full rounded-md border-0 py-1.5 pl-7 pr-12 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="price-currency"
              />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-300 sm:text-sm" id="price-currency">EUR</span>
              </div>
            </div>

            <input
              v-if="!waterProof && dataForm.waterAmount > 0"
              v-on:change="handleFileUpload($event, 6.1)"
              type="file"
              name="small-file-input"
              id="small-file-input"
              class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
            />

            <p v-if="errors.waterProof" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
              {{ errors.waterProof }}
            </p>

            <div v-if="waterProof" class="mb-2 mt-3 flex justify-between items-center">
              <div class="flex items-center gap-x-3">
                <div>
                  <p class="text-sm font-medium text-gray-800 dark:text-white">
                    {{ waterProof?.name }}
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-500">
                    {{ (waterProof?.size / 1000).toFixed(0) }} KB
                  </p>
                </div>
              </div>
            </div>

            <div v-if="waterProof" class="flex items-center gap-x-3 whitespace-nowrap">
              <div
                class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
                role="progressbar"
                aria-valuenow="1"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
                  :style="`width: ${uploadPercentage}%`"
                ></div>
              </div>
              <div class="w-6 text-end">
                <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
              </div>
            </div>
          </TabPanel>

          <TabPanel
            key="2"
            :class="[
              'rounded-xl bg-transparent p-3',
              'ring-white/60 ring-offset-2 focus:outline-none '
            ]"
          >
            <div class="relative z-0 mt-2 rounded-md shadow-sm mb-3">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-300 sm:text-sm">€</span>
              </div>
              <input
                type="text"
                v-model="dataForm.elecAmount"
                name="price"
                id="price"
                class="block text-white bg-transparent w-full rounded-md border-0 py-1.5 pl-7 pr-12 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="price-currency"
              />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-300 sm:text-sm" id="price-currency">EUR</span>
              </div>
            </div>

            <input
              v-if="!elecProof && dataForm.elecAmount > 0"
              v-on:change="handleFileUpload($event, 6.2)"
              type="file"
              name="small-file-input"
              id="small-file-input"
              class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
            />

            <p v-if="errors.elecProof" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
              {{ errors.elecProof }}
            </p>

            <div v-if="elecProof" class="mb-2 mt-3 flex justify-between items-center">
              <div class="flex items-center gap-x-3">
                <div>
                  <p class="text-sm font-medium text-gray-800 dark:text-white">
                    {{ elecProof?.name }}
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-500">
                    {{ (elecProof?.size / 1000).toFixed(0) }} KB
                  </p>
                </div>
              </div>
            </div>

            <div v-if="elecProof" class="flex items-center gap-x-3 whitespace-nowrap">
              <div
                class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
                role="progressbar"
                aria-valuenow="1"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
                  :style="`width: ${uploadPercentage}%`"
                ></div>
              </div>
              <div class="w-6 text-end">
                <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
              </div>
            </div>
          </TabPanel>

          <TabPanel
            key="3"
            :class="[
              'rounded-xl bg-transparent p-3',
              'ring-white/60 ring-offset-2 focus:outline-none '
            ]"
          >
            <div class="relative z-0 mt-2 rounded-md shadow-sm mb-3">
              <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <span class="text-gray-300 sm:text-sm">€</span>
              </div>
              <input
                type="text"
                v-model="dataForm.gazAmount"
                name="price"
                id="price"
                class="block text-white bg-transparent w-full rounded-md border-0 py-1.5 pl-7 pr-12 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                placeholder="0.00"
                aria-describedby="price-currency"
              />
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <span class="text-gray-300 sm:text-sm" id="price-currency">EUR</span>
              </div>
            </div>

            <input
              v-if="!gazProof && dataForm.gazAmount > 0"
              v-on:change="handleFileUpload($event, 6)"
              type="file"
              name="small-file-input"
              id="small-file-input"
              class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
            />

            <p v-if="errors.gazProof" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
              {{ errors.gazProof }}
            </p>

            <div v-if="gazProof" class="mb-2 mt-3 flex justify-between items-center">
              <div class="flex items-center gap-x-3">
                <div>
                  <p class="text-sm font-medium text-gray-800 dark:text-white">
                    {{ gazProof?.name }}
                  </p>
                  <p class="text-xs text-gray-500 dark:text-gray-500">
                    {{ (gazProof?.size / 1000).toFixed(0) }} KB
                  </p>
                </div>
              </div>
            </div>

            <div v-if="gazProof" class="flex items-center gap-x-3 whitespace-nowrap">
              <div
                class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
                role="progressbar"
                aria-valuenow="1"
                aria-valuemin="0"
                aria-valuemax="100"
              >
                <div
                  class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
                  :style="`width: ${uploadPercentage}%`"
                ></div>
              </div>
              <div class="w-6 text-end">
                <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </TabGroup>

      <button
        type="button"
        @click="
          () => {
            if (dataForm.gazAmount > 0 && !dataForm.gazInvoice) return

            if (dataForm.elecAmount > 0 && !dataForm.elecInvoice) return

            if (dataForm.waterAmount > 0 && !dataForm.waterInvoice) return

            startShowNextStep()
          }
        "
        class="mt-3 w-full sm:col-span-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <CheckCircleIcon class="ml-auto h-5 w-5" aria-hidden="true" />
        <span class="text-center mr-auto">Valider</span>
      </button>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep7"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <div class="max-w-2xl m-auto">
        <div class="relative z-0 mt-2 rounded-md shadow-sm mb-3">
          <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <span class="text-gray-300 sm:text-sm">€</span>
          </div>
          <input
            type="number"
            v-model="dataForm.otherAmount"
            name="price"
            id="price"
            class="block text-white bg-transparent w-full rounded-md border-0 py-1.5 pl-7 pr-12 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
            placeholder="0.00"
            aria-describedby="price-currency"
          />
          <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <span class="text-gray-300 sm:text-sm" id="price-currency">EUR</span>
          </div>
        </div>

        <label for="comment" class="block text-sm font-medium leading-6 text-white"
          >Explication/Commentaire</label
        >
        <div class="mt-2">
          <textarea
            rows="4"
            name="comment"
            id="comment"
            v-model="dataForm.otherComment"
            class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>

        <input
          v-if="!otherProof"
          v-on:change="handleFileUpload($event, 7)"
          type="file"
          name="small-file-input"
          id="small-file-input"
          class="mt-2 block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
        />

        <p v-if="errors.otherProof" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
          {{ errors.otherProof }}
        </p>

        <div v-if="otherProof" class="mb-2 mt-3 flex justify-between items-center">
          <div class="flex items-center gap-x-3">
            <div>
              <p class="text-sm font-medium text-gray-800 dark:text-white">
                {{ otherProof?.name }}
              </p>
              <p class="text-xs text-gray-500 dark:text-gray-500">
                {{ (otherProof?.size / 1000).toFixed(0) }} KB
              </p>
            </div>
          </div>
        </div>

        <div v-if="otherProof" class="flex items-center gap-x-3 whitespace-nowrap">
          <div
            class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
            role="progressbar"
            aria-valuenow="1"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
              :style="`width: ${uploadPercentage}%`"
            ></div>
          </div>
          <div class="w-6 text-end">
            <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
          </div>
        </div>

        <button
          type="button"
          @click="startShowNextStep"
          class="mt-3 w-full sm:col-span-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <CheckCircleIcon class="ml-auto h-5 w-5" aria-hidden="true" />
          <span class="text-center mr-auto">Valider</span>
        </button>
      </div>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep8"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <input
        v-if="!handicapProof"
        v-on:change="handleFileUpload($event, 8)"
        type="file"
        name="small-file-input"
        id="small-file-input"
        class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
      />

      <p v-if="errors.handicapProof" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
        {{ errors.handicapProof }}
      </p>

      <div v-if="handicapProof" class="mb-2 mt-3 flex justify-between items-center">
        <div class="flex items-center gap-x-3">
          <div>
            <p class="text-sm font-medium text-gray-800 dark:text-white">
              {{ handicapProof?.name }}
            </p>
            <p class="text-xs text-gray-500 dark:text-gray-500">
              {{ (handicapProof?.size / 1000).toFixed(0) }} KB
            </p>
          </div>
        </div>
      </div>

      <div v-if="handicapProof" class="flex items-center gap-x-3 whitespace-nowrap">
        <div
          class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
          role="progressbar"
          aria-valuenow="1"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
            :style="`width: ${uploadPercentage}%`"
          ></div>
        </div>
        <div class="w-6 text-end">
          <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
        </div>
      </div>

      <button
        type="button"
        @click="validateForm"
        class="mt-3 w-full sm:col-span-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <CheckCircleIcon class="ml-auto h-5 w-5" aria-hidden="true" />
        <span class="text-center mr-auto">Valider</span>
      </button>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, type Ref } from 'vue'
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core'
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  TransitionRoot
} from '@headlessui/vue'
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon
} from '@heroicons/vue/16/solid'
import GraphemeSplitter from 'grapheme-splitter'
import axios from 'axios'
import { useUserForm } from '@/stores/userForm'

const props = defineProps({
  onFormFinish: {
    type: Function
  }
})
const dataForm = defineModel<
  | {
      scolarship: string
      rentReceipt: string
      handicapProof: string
      bourseProof: string
      gazInvoice: string
      elecInvoice: string
      waterInvoice: string
      revenueProof: string
      aplProof: string
      otherProof: string
      revenueAmount: number
      waterAmount: number
      elecAmount: number
      gazAmount: number
      aplAmount: number
      bourseGrade: number
      rentAmount: number
      rentType: number
      otherAmount: number
      otherComment: string
    }
  | any
>()

dataForm.value = { scolarship: '' }

const errors = ref({
  scolarship: '',
  rentReceipt: '',
  handicapProof: '',
  bourseProof: '',
  revenueProof: '',
  aplProof: '',
  otherProof: '',
  revenueAmount: 0,
  waterProof: '',
  elecProof: '',
  gazProof: '',
  aplAmount: 0,
  bourseGrade: '',
  rentAmount: '',
  otherAmount: 0
})

const homeTypes = [
  {
    name: 'Maison Individuelle',
    value: 0
  },
  {
    name: 'Appartement Individuelle',
    value: 1
  },
  {
    name: 'Heberger chez les parents',
    value: 2
  },
  {
    name: 'Co-Location',
    value: 3
  },
  {
    name: 'CROUS/NEXITY',
    value: 4
  },
  {
    name: 'Autre',
    value: 5
  }
]

const bourseGrades = [
  {
    name: 'Je ne suis pas eligible a la bourse',
    value: -1
  },
  {
    name: 'Echelon 0 bis',
    value: 0
  },
  {
    name: 'Echelon 1',
    value: 1
  },
  {
    name: 'Echelon 2',
    value: 2
  },
  {
    name: 'Echelon 3',
    value: 3
  },
  {
    name: 'Echelon 4',
    value: 4
  },
  {
    name: 'Echelon 5',
    value: 5
  },
  {
    name: 'Echelon 6',
    value: 6
  },
  {
    name: 'Echelon 7',
    value: 7
  }
]

const showFormStep1 = ref(false)
const showFormStep2 = ref(false)
const showFormStep3 = ref(false)
const showFormStep4 = ref(false)
const showFormStep5 = ref(false)
const showFormStep6 = ref(false)
const showFormStep7 = ref(false)
const showFormStep8 = ref(false)

const scolarship = ref()
const revenueProof = ref()
const aplProof = ref()
const bourseProof = ref()
const rentReceipt = ref()
const waterProof = ref()
const elecProof = ref()
const gazProof = ref()
const otherProof = ref()
const handicapProof = ref()

const stringSplitter = (string: string) => {
  const splitter = new GraphemeSplitter()
  return splitter.splitGraphemes(string)
}

const step = ref(-1)
const phrase = ref<string>()
const typewriter = ref()
const uploadPercentage = ref(0)
let refToShow: Ref<Boolean>

function skipAnimation() {
  refToShow.value = true
}

watch(
  () => step.value,
  (newValue, oldValue) => {
    showFormStep1.value = false
    showFormStep2.value = false

    switch (newValue) {
      case 0:
        refToShow = showFormStep1
        phrase.value = '<br /><br />Merci de nous fournir ton justificatif étudiant'
        break
      case 1:
        refToShow = showFormStep2
        phrase.value =
          '<div><div />Reçois-tu un salaire, si oui merci de fournir le montant et la fiche de paie'
        break
      case 2:
        refToShow = showFormStep3
        phrase.value =
          "<div><div />Es-tu éligible aux APL (CAF), si oui merci de fournir le montant et l'attestation"
        break
      case 3:
        refToShow = showFormStep4
        phrase.value =
          "<div><div />Es-tu éligible à la Bourse, si oui merci de fournir l'echelon et l'attestation"
        break
      case 4:
        refToShow = showFormStep5
        phrase.value =
          "<div><div />Qu'elle est ton hébergement actuel, et le justificatif qui va avec"
        break
      case 5:
        refToShow = showFormStep6
        phrase.value =
          '<div><div />Quelles sont tes charges mensuelles, merci de fournir les montants et les justificatifs'
        break
      case 6:
        refToShow = showFormStep7
        phrase.value =
          "<div><div />Auras-tu d'autre charges importantes à nous communiquer ? Si oui, merci d'indiquer le montant total, un commentaire pour expliquer au mieux et de fournir un justificatif"
        break
      case 7:
        refToShow = showFormStep8
        phrase.value =
          "<div><div />Est-tu atteint d'un handicap quelconque, si oui, merci de fournir le justificatif, sinon vous pouvez valider"
        break
      default:
        phrase.value = ''
        break
    }

    ;(newValue > 0 || (newValue === 0 && oldValue >= 1)
      ? typewriter.value.deleteAll(-1000)
      : typewriter.value
    )
      .typeString(phrase.value)
      .callFunction(() => {
        refToShow.value = true
      })
      .start()
  }
)

onMounted(() => {
  typewriter.value = new Typewriter('#animated-text', { delay: 30, stringSplitter })

  typewriter.value
    .deleteAll(-1000)
    .typeString(
      "<div><div />Nous allons maintenant passer à la partie la plus importante ! Merci de remplir cela sérieusement, car toute erreur se verra refuser l'inscription 🥲"
    )
    .pauseFor(300)
    .callFunction(() => step.value++)
    .start()
})

async function handleFileUpload(event: any, fileType: number) {
  if (event.target.files[0]) {
    if (fileType === 0) {
      scolarship.value = event.target.files[0]
      errors.value.scolarship = ''

      try {
        const data = await submitFile('scolarship', event.target.files[0])

        dataForm.value!.scolarship = data.uuid

        startShowNextStep()
      } catch (error: any) {
        dataForm.value.scolarship = null
        scolarship.value = null

        errors.value.scolarship = error.response?.data?.message || error.message
      }
    } else if (fileType === 1) {
      revenueProof.value = event.target.files[0]
      errors.value.revenueProof = ''

      try {
        const data = await submitFile('revenue', event.target.files[0])

        dataForm.value!.revenueProof = data.uuid

        startShowNextStep()
      } catch (error: any) {
        dataForm.value.revenueProof = null
        revenueProof.value = null

        errors.value.revenueProof = error.response?.data?.message || error.message
      }
    } else if (fileType === 3) {
      aplProof.value = event.target.files[0]
      errors.value.aplProof = ''

      try {
        const data = await submitFile('apl', event.target.files[0])

        dataForm.value!.aplProof = data.uuid

        startShowNextStep()
      } catch (error: any) {
        dataForm.value.aplProof = null
        aplProof.value = null

        errors.value.aplProof = error.response?.data?.message || error.message
      }
    } else if (fileType === 4) {
      bourseProof.value = event.target.files[0]
      errors.value.bourseProof = ''

      try {
        const data = await submitFile('bourse', event.target.files[0])

        dataForm.value!.bourseProof = data.uuid
      } catch (error: any) {
        dataForm.value.bourseProof = null
        bourseProof.value = null

        errors.value.bourseProof = error.response?.data?.message || error.message
      }
    } else if (fileType === 5) {
      errors.value.rentReceipt = ''
      rentReceipt.value = event.target.files[0]

      try {
        const data = await submitFile('rentReceipt', event.target.files[0])

        dataForm.value!.rentReceipt = data.uuid

        startShowNextStep()
      } catch (error: any) {
        dataForm.value.rentReceipt = null
        rentReceipt.value = null

        errors.value.rentReceipt = error.response?.data?.message || error.message
      }
    } else if (fileType === 6) {
      errors.value.gazProof = ''
      gazProof.value = event.target.files[0]

      try {
        const data = await submitFile('gaz', event.target.files[0])

        dataForm.value!.gazInvoice = data.uuid
      } catch (error: any) {
        dataForm.value.gazInvoice = null
        gazProof.value = null

        errors.value.gazProof = error.response?.data?.message || error.message
      }
    } else if (fileType === 6.1) {
      errors.value.waterProof = ''
      waterProof.value = event.target.files[0]
      try {
        const data = await submitFile('water', event.target.files[0])

        dataForm.value!.waterInvoice = data.uuid
      } catch (error: any) {
        dataForm.value.waterInvoice = null
        waterProof.value = null

        errors.value.waterProof = error.response?.data?.message || error.message
      }
    } else if (fileType === 6.2) {
      errors.value.elecProof = ''
      elecProof.value = event.target.files[0]
      try {
        const data = await submitFile('electricity', event.target.files[0])

        dataForm.value!.elecInvoice = data.uuid
      } catch (error: any) {
        dataForm.value.elecInvoice = null
        elecProof.value = null

        errors.value.elecProof = error.response?.data?.message || error.message
      }
    } else if (fileType === 7) {
      errors.value.otherProof = ''
      otherProof.value = event.target.files[0]
      try {
        const data = await submitFile('other', event.target.files[0])

        dataForm.value!.otherProof = data.uuid
      } catch (error: any) {
        dataForm.value.otherProof = null
        otherProof.value = null

        errors.value.otherProof = error.response?.data?.message || error.message
      }
    } else if (fileType === 8) {
      errors.value.handicapProof = ''
      handicapProof.value = event.target.files[0]

      try {
        const data = await submitFile('handicapProof', event.target.files[0])

        dataForm.value!.handicapProof = data.uuid

        startShowNextStep()
      } catch (error: any) {
        dataForm.value.handicapProof = null
        handicapProof.value = null

        errors.value.handicapProof = error.response?.data?.message || error.message
      }
    }
  }
}

function startShowNextStep() {
  switch (step.value) {
    case 0:
      showFormStep1.value = false
      break
    case 1:
      showFormStep2.value = false
      break
    case 2:
      showFormStep3.value = false
      break
    case 3:
      showFormStep4.value = false
      break
    case 4:
      showFormStep5.value = false
      break
    case 5:
      showFormStep6.value = false
      break
    case 6:
      if (
        dataForm.value.otherAmount &&
        dataForm.value.otherAmount > 0 &&
        !dataForm.value.otherProof
      ) {
        errors.value.otherProof = 'Other proof is mandatory'
        return
      }

      showFormStep7.value = false
      break
    default:
      break
  }

  step.value++
}

async function submitFile(type: string, file: any) {
  let formData = new FormData()

  formData.append('document', file)
  formData.append('type', type)

  const response = await axios.post(
    import.meta.env.VITE_APP_BASE_URL + '/documents/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function (progressEvent: any) {
        uploadPercentage.value = Math.round((progressEvent.loaded / progressEvent.total) * 100)
      }
    }
  )

  return response.data
}

function validateForm() {
  showFormStep8.value = false

  const store = useUserForm()

  store.updateMoreAboutYourLife(dataForm.value)

  if (props.onFormFinish) props.onFormFinish()
}
</script>
