import { string } from 'yup'

export default string()
  .required('Password is required')
  .min(8, 'Need to be at least 8 characters')
  .matches(
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/,
    'Password need to have at least one upper case letter, one lower case letter, one number and one special character'
  )
  .max(255, 'Password can be highter than 255 characters')
