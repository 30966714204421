<template>
  <div class="my-3">
    <span
      class="isolate inline-flex rounded-md shadow-sm w-full m-auto mb-10 justify-between gap-4"
    >
      <button
        :disabled="step <= 0"
        @click="step > 0 ? step-- : null"
        type="button"
        class="font-bold text-sm relative inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:bg-blue-400 hover:ring-blue-400 focus:z-10"
      >
        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
        <span>Précédent</span>
      </button>
      <button
        :disabled="step < 0"
        @click="step >= 0 ? skipAnimation() : null"
        type="button"
        class="font-bold text-sm relative -ml-px inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:ring-blue-400 hover:bg-blue-400 focus:z-10"
      >
        <span>Passer l'animation</span>
        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </span>

    <div class="text-center text-lg sm:text-3xl" style="font-family: 'Simple Plan'">
      <div id="animated-text"></div>
    </div>

    <TransitionRoot
      appear
      :show="showFormStep1"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <input
        v-if="!cniRecto"
        v-on:change="handleFileUpload($event, 0)"
        type="file"
        name="small-file-input"
        id="small-file-input"
        class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
      />

      <p v-if="errors.cniRecto" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
        {{ errors.cniRecto }}
      </p>

      <div v-if="cniRecto" class="mb-2 mt-3 flex justify-between items-center">
        <div class="flex items-center gap-x-3">
          <div>
            <p class="text-sm font-medium text-gray-800 dark:text-white">{{ cniRecto?.name }}</p>
            <p class="text-xs text-gray-500 dark:text-gray-500">
              {{ (cniRecto?.size / 1000).toFixed(0) }} KB
            </p>
          </div>
        </div>
      </div>

      <div v-if="cniRecto" class="flex items-center gap-x-3 whitespace-nowrap">
        <div
          class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
          role="progressbar"
          aria-valuenow="1"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
            :style="`width: ${uploadPercentage}%`"
          ></div>
        </div>
        <div class="w-6 text-end">
          <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
        </div>
      </div>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep2"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <input
        v-if="!cniVerso"
        v-on:change="handleFileUpload($event, 1)"
        type="file"
        name="small-file-input"
        id="small-file-input"
        class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
      />

      <p v-if="errors.cniVerso" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
        {{ errors.cniVerso }}
      </p>

      <div v-if="cniVerso" class="mb-2 mt-3 flex justify-between items-center">
        <div class="flex items-center gap-x-3">
          <div>
            <p class="text-sm font-medium text-gray-800 dark:text-white">{{ cniVerso?.name }}</p>
            <p class="text-xs text-gray-500 dark:text-gray-500">
              {{ (cniVerso?.size / 1000).toFixed(0) }} KB
            </p>
          </div>
        </div>
      </div>

      <div v-if="cniVerso" class="flex items-center gap-x-3 whitespace-nowrap">
        <div
          class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
          role="progressbar"
          aria-valuenow="1"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
            :style="`width: ${uploadPercentage}%`"
          ></div>
        </div>
        <div class="w-6 text-end">
          <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
        </div>
      </div>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep3"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center"
    >
      <input
        v-if="!selfie"
        v-on:change="handleFileUpload($event, 2)"
        type="file"
        accept="image/*"
        capture="user"
        name="small-file-input"
        id="small-file-input"
        class="block w-full border border-gray-200 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none file:bg-gray-50 file:border-0 file:me-4 file:py-2 file:px-4"
      />

      <p v-if="errors.selfie" class="mt-2 ml-1 text-sm text-red-600" id="password-error">
        {{ errors.selfie }}
      </p>

      <div v-if="selfie" class="mb-2 mt-3 flex justify-between items-center">
        <div class="flex items-center gap-x-3">
          <div>
            <p class="text-sm font-medium text-gray-800 dark:text-white">{{ selfie?.name }}</p>
            <p class="text-xs text-gray-500 dark:text-gray-500">
              {{ (selfie?.size / 1000).toFixed(0) }} KB
            </p>
          </div>
        </div>
      </div>

      <div v-if="selfie" class="flex items-center gap-x-3 whitespace-nowrap">
        <div
          class="flex w-full h-2 bg-gray-200 rounded-full overflow-hidden dark:bg-gray-700"
          role="progressbar"
          aria-valuenow="1"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          <div
            class="flex flex-col justify-center rounded-full overflow-hidden bg-blue-600 text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500"
            :style="`width: ${uploadPercentage}%`"
          ></div>
        </div>
        <div class="w-6 text-end">
          <span class="text-sm text-gray-800 dark:text-white">{{ uploadPercentage }}%</span>
        </div>
      </div>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep4"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex-row gap-2 mt-3 justify-center overflow-scroll"
    >
      <a
        target="_blank"
        :href="conventionURL"
        class="mt-3 w-full sm:col-span-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
      >
        <CheckCircleIcon class="ml-auto h-5 w-5" aria-hidden="true" />
        <span class="text-center mr-auto">Voir la convention</span>
      </a>

      <div class="mt-2">
        <label class="text-gray-500 dark:text-gray-300 mt-2 font-bold">Signature</label>
      </div>

      <Vue3Signature
        ref="signature1"
        :disabled="false"
        :sigOption="{
          penColor: ' rgb(0, 0, 0)',
          backgroundColor: 'rgb(255,255,255)'
        }"
        h="200px"
        class="w-full mt-2"
      >
      </Vue3Signature>

      <button
        type="button"
        @click="validateForm"
        class="mt-3 w-full sm:col-span-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <CheckCircleIcon class="ml-auto h-5 w-5" aria-hidden="true" />
        <span class="text-center mr-auto">Valider</span>
      </button>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { TransitionRoot } from '@headlessui/vue'
import { computed, onMounted, ref, watch, type Ref } from 'vue'
import { CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/16/solid'
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core'
import axios from 'axios'
import { useUserForm } from '@/stores/userForm'
import moment from 'moment'
import Vue3Signature from 'vue3-signature'
import GraphemeSplitter from 'grapheme-splitter'

const dataForm = defineModel<
  | {
      sign: string
      cniRecto: string
      cniVerso: string
      selfie: string
    }
  | any
>()

dataForm.value = {}

const props = defineProps({
  isDeliverer: {
    type: Boolean
  },
  onFormFinish: {
    type: Function
  }
})

const showFormStep1 = ref(false)
const showFormStep2 = ref(false)
const showFormStep3 = ref(false)
const showFormStep4 = ref(false)

const errors = ref({
  cniRecto: '',
  cniVerso: '',
  selfie: '',
  convention: '',
  sign: ''
})

const typewriter = ref()

const cniRecto = ref()
const cniVerso = ref()
const selfie = ref()

const signature1 = ref<typeof Vue3Signature>()

const uploadPercentage = ref(0)

const conventionURL = computed(() => {
  const userForm = useUserForm()

  const address = userForm.AddressAndCityForm?.address.displayLines.join(',')

  return (
    import.meta.env.VITE_APP_BASE_URL +
    '/user/convention' +
    `?firstName=${userForm.DelivererOrStudentForm?.firstName}&lastName=${userForm.DelivererOrStudentForm?.lastName}&address=${address}&postalCode=&birthDate=${moment(
      userForm.MoreInfosAboutYouForm?.birthDate,
      'DD/MM/YYYY'
    ).toISOString()}`
  )
})

const step = ref(-1)
const phrase = ref<string>()
let refToShow: Ref<Boolean>

function skipAnimation() {
  //TODO IF props
  refToShow.value = true
}

watch(
  () => step.value,
  (newValue, oldValue) => {
    showFormStep1.value = false
    showFormStep2.value = false
    showFormStep3.value = false
    showFormStep4.value = false

    switch (newValue) {
      case 0:
        refToShow = showFormStep1
        phrase.value = "<br />Commençons par le recto de ta carte d'identite &#x1FAAA; "
        break
      case 1:
        refToShow = showFormStep2
        phrase.value = `<div><div />Nous avons maintenant besoin du verso de ta carte d'identite &#x1FAAA;`
        break
      case 2:
        refToShow = showFormStep3
        phrase.value =
          "<div><div />Nous avons maintenant besoin d'un selfie actuel de toi &#x1F933;"
        break
      case 3:
        refToShow = showFormStep4
        phrase.value =
          "<div><div />Nous avons maintenant besoin que tu signes la convention bénévole apres l'avoir lue &#x1F4DD; "
        break
      default:
        phrase.value = ''
        break
    }

    ;(newValue > 0 || (newValue === 0 && oldValue >= 1)
      ? typewriter.value.deleteAll(-1000)
      : typewriter.value
    )
      .typeString(phrase.value)
      .callFunction(() => {
        refToShow.value = true
      })
      .start()
  }
)

const stringSplitter = (string: string) => {
  const splitter = new GraphemeSplitter()
  return splitter.splitGraphemes(string)
}

onMounted(() => {
  typewriter.value = new Typewriter('#animated-text', { delay: 25, stringSplitter })

  typewriter.value
    .typeString(
      'Dernière étape ! Nous allons avoir besoin que tu nous transmettes plusieurs documents'
    )
    .callFunction(() => {
      step.value++
    })
    .start()
})

async function handleFileUpload(event: any, fileType: number) {
  if (fileType === 0) {
    cniRecto.value = event.target.files[0]
  } else if (fileType === 1) {
    cniVerso.value = event.target.files[0]
  } else if (fileType === 2) {
    selfie.value = event.target.files[0]
  }

  if (event.target.files[0]) {
    if (fileType === 0) {
      errors.value.cniRecto = ''

      try {
        const data = await submitFile('cni-recto', event.target.files[0])

        dataForm.value!.cniRecto = data.uuid

        startStepShowStep2()
      } catch (error: any) {
        cniRecto.value = null

        errors.value.cniRecto = error.response?.data?.message || error.message
      }
    } else if (fileType === 1) {
      errors.value.cniRecto = ''

      try {
        const data = await submitFile('cni-verso', event.target.files[0])

        dataForm.value!.cniVerso = data.uuid

        startStepShowStep3()
      } catch (error: any) {
        cniVerso.value = null

        errors.value.cniVerso = error.response?.data?.message || error.message
      }
    } else if (fileType === 2) {
      errors.value.selfie = ''

      try {
        const data = await submitFile('selfie', event.target.files[0])

        dataForm.value!.selfie = data.uuid

        startStepShowStep4()
      } catch (error: any) {
        selfie.value = null

        errors.value.selfie = error.response?.data?.message || error.message
      }
    }
  }
}

function startStepShowStep2() {
  showFormStep1.value = false

  step.value++
}

function startStepShowStep3() {
  showFormStep2.value = false

  step.value++
}

function startStepShowStep4() {
  showFormStep3.value = false

  if (!props.isDeliverer) {
    validateForm()
    return
  }

  step.value++
}

async function validateForm() {
  showFormStep4.value = false

  dataForm.value!.sign = signature1.value?.save('image/jpeg')

  const store = useUserForm()

  store.updateLegalStuffForm(dataForm.value)

  showFormStep4.value = false

  if (props.onFormFinish) props.onFormFinish()
}

async function submitFile(type: string, file: any) {
  let formData = new FormData()

  formData.append('document', file)
  formData.append('type', type)

  const response = await axios.post(
    import.meta.env.VITE_APP_BASE_URL + '/documents/upload',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: function (progressEvent: any) {
        uploadPercentage.value = Math.round((progressEvent.loaded / progressEvent.total) * 100)
      }
    }
  )

  return response.data
}
</script>
