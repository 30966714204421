<template>
  <div class="my-3">
    <span
      class="isolate inline-flex rounded-md shadow-sm w-full m-auto mb-10 justify-between gap-4"
    >
      <button
        :disabled="step <= 0"
        @click="step > 0 ? step-- : null"
        type="button"
        class="font-bold text-sm relative inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:bg-blue-400 hover:ring-blue-400 focus:z-10"
      >
        <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
        <span>Précédent</span>
      </button>
      <button
        :disabled="step < 0"
        @click="step >= 0 ? skipAnimation() : null"
        type="button"
        class="font-bold text-sm relative -ml-px inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:ring-blue-400 hover:bg-blue-400 focus:z-10"
      >
        <span>Passer l'animation</span>
        <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
      </button>
    </span>

    <div class="text-center text-lg sm:text-3xl" style="font-family: 'Simple Plan'">
      <div id="animated-text"></div>
    </div>

    <TransitionRoot
      appear
      :show="showFormStep1"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <Combobox v-model="address">
        <div class="max-w-md w-full">
          <p class="mx-auto mt-2 mb-2 text-sm text-white">
            Merci de renseigner votre adresse complète (numéro + nom de rue inclus)
          </p>

          <div
            class="relative w-full cursor-default overflow-hidden rounded-lg bg-white text-left shadow-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-teal-300 sm:text-sm"
          >
            <ComboboxInput
              class="w-full border-none py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 max-w-md"
              :displayValue="(address: any) => address?.displayLines?.join(', ')"
              @change="queryAddress = $event.target.value"
            />
            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
            </ComboboxButton>
          </div>

          <p v-if="errors.address" class="absolute mt-2 text-sm text-red-600" id="address-error">
            {{ errors.address }}
          </p>

          <TransitionRoot
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            @after-leave="queryAddress = ''"
          >
            <ComboboxOptions
              class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm max-w-md"
            >
              <div
                v-if="autoCompletePlaces.length === 0 && queryAddress !== ''"
                class="relative cursor-default select-none px-4 py-2 text-gray-700"
              >
                <template v-if="queryAddress.length > 20">
                  {{ isLoading ? 'Address loading...' : 'No address found.' }}
                </template>

                <template v-if="queryAddress.length < 20">
                  {{ '20 Characters minimum needed' }}
                </template>
              </div>

              <ComboboxOption
                v-for="address in autoCompletePlaces.filter((loc) => loc.location)"
                as="template"
                :key="address.location.latitude"
                :value="address"
                v-slot="{ selected, active }"
              >
                <li
                  class="relative cursor-default select-none py-2 pl-2 pr-4"
                  :class="{
                    'bg-blue-600 text-white': active,
                    'text-gray-900': !active
                  }"
                >
                  <span
                    class="block truncate"
                    :class="{ 'font-medium': selected, 'font-normal': !selected }"
                  >
                    {{ address.displayLines.join(', ') }}
                  </span>
                  <span
                    v-if="selected"
                    class="absolute inset-y-0 left-0 flex items-center pl-3"
                    :class="{ 'text-white': active, 'text-blue-600': !active }"
                  >
                    <CheckIcon class="h-5 w-5" aria-hidden="true" />
                  </span>
                </li>
              </ComboboxOption>
            </ComboboxOptions>
          </TransitionRoot>
        </div>
      </Combobox>

      <button
        type="button"
        @click="startStepShowStep2"
        class="my-auto mt-4 inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Valider
      </button>
    </TransitionRoot>

    <TransitionRoot
      appear
      :show="showFormStep2"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <div class="relative w-full sm:max-w-sm" v-if="!props.isDeliverer">
        <input
          type="text"
          name="schoolName"
          id="schoolName"
          class="block w-full bg-transparent rounded-md border-0 py-1.5 text-white shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="Ecole 42"
          v-model="schoolName"
        />
        <div
          v-if="errors.schoolName"
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3 mt-6"
        >
          <ExclamationCircleIcon class="h-5 w-5 text-red-500" aria-hidden="true" />
        </div>
        <p
          v-if="errors.schoolName"
          class="absolute mt-2 ml-1 text-sm text-red-600"
          id="schoolName-error"
        >
          {{ errors.schoolName }}
        </p>
      </div>

      <div v-if="props.isDeliverer" class="max-w-md w-full">
        <Listbox v-model="sector">
          <div class="mt-1 relative">
            <ListboxButton
              class="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
            >
              <span class="block truncate text-black">{{
                sector?.name || 'Choisisez une ville'
              }}</span>
              <span class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                <ChevronUpDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </ListboxButton>

            <transition
              leave-active-class="transition duration-100 ease-in"
              leave-from-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <ListboxOptions
                class="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
              >
                <ListboxOption
                  v-slot="{ active, selected }"
                  v-for="sector in sectors"
                  :key="sector._id"
                  :value="sector"
                >
                  <li
                    :class="[
                      active ? 'bg-blue-100 text-blue-900' : 'text-gray-900',
                      'relative cursor-default select-none py-2 pl-10 pr-4'
                    ]"
                  >
                    <span :class="[selected ? 'font-medium' : 'font-normal', 'block truncate']"
                      >{{ sector.name }}
                    </span>
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3 text-blue-600"
                    >
                      <CheckIcon class="h-5 w-5" aria-hidden="true" />
                    </span>
                  </li>
                </ListboxOption>
              </ListboxOptions>
            </transition>
          </div>
        </Listbox>
      </div>

      <button
        type="button"
        @click="validateForm"
        class="my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
      >
        <CheckCircleIcon class="-ml-0.5 h-5 w-5" aria-hidden="true" />
        Valider
      </button>
    </TransitionRoot>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, type Ref } from 'vue'
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  TransitionRoot
} from '@headlessui/vue'
import {
  CheckCircleIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpDownIcon,
  ExclamationCircleIcon
} from '@heroicons/vue/16/solid'

import axios from 'axios'
import { yupSchemas } from '@/schemas'
import { string } from 'yup'
import GraphemeSplitter from 'grapheme-splitter'
import { useUserForm } from '@/stores/userForm'

const props = defineProps({
  isDeliverer: {
    type: Boolean
  },
  onFormFinish: {
    type: Function
  }
})

const dataForm = defineModel<
  | {
      address: {
        location: {
          latitude: number
          longitude: number
        }
        displayLines: string[]
        completionUrl: string
      }
      schoolName?: string
      sector?: string
    }
  | any
>()

dataForm.value = {}

const typewriter = ref()
const autoCompleteTimeout = ref()
const autoCompletePlaces = ref<any[]>([])
const sectors = ref<any[]>([])
const address = ref(dataForm.value?.address)
const schoolName = ref(dataForm.value?.schoolName)
const sector = ref(dataForm.value?.sector)
const queryAddress = ref('')
const showFormStep1 = ref(false)
const showFormStep2 = ref(false)
const isLoading = ref(false)
const nbRequest = ref(0)

watch(
  () => queryAddress.value,
  async () => {
    isLoading.value = true

    await searchPlace(queryAddress.value)
  }
)

watch(
  () => address.value,
  () => (errors.value.address = '')
)
watch(
  () => schoolName.value,
  () => (errors.value.schoolName = '')
)
watch(
  () => sector.value,
  () => (errors.value.sector = '')
)

const errors = ref({
  address: '',
  schoolName: '',
  sector: ''
})

const stringSplitter = (string: string) => {
  const splitter = new GraphemeSplitter()
  return splitter.splitGraphemes(string)
}

const step = ref(-1)
const phrase = ref<string>()
let refToShow: Ref<Boolean>

function skipAnimation() {
  refToShow.value = true
}

watch(
  () => step.value,
  (newValue, oldValue) => {
    showFormStep1.value = false
    showFormStep2.value = false

    switch (newValue) {
      case 0:
        refToShow = showFormStep1
        phrase.value = '<br /><br />Quelle est ton adresse ? &#x1F3E1;'
        break
      case 1:
        refToShow = showFormStep2
        phrase.value = props.isDeliverer
          ? `<div><div />C'est noté ! Nous aurions besoin de savoir quelle antenne Delivraide tu souhaites rejoindre ? &#x1F4E1;`
          : `<div><div />C'est noté ! Nous aurions besoin de savoir dans quel école tu étudie ? &#x1F3EB;`
        break
      default:
        phrase.value = ''
        break
    }

    ;(newValue > 0 || (newValue === 0 && oldValue >= 1)
      ? typewriter.value.deleteAll(-1000)
      : typewriter.value
    )
      .typeString(phrase.value)
      .callFunction(() => {
        refToShow.value = true
      })
      .start()
  }
)

onMounted(() => {
  axios
    .get(import.meta.env.VITE_APP_BASE_URL + '/sector/list')
    .then((response) => {
      sectors.value = response.data.sectors
    })
    .catch((error) => console.error(error))

  typewriter.value = new Typewriter('#animated-text', { delay: 30, stringSplitter })

  typewriter.value
    .deleteAll(-1000)
    .typeString(
      "<div><div />Top ! Nous avons besoin d'en savoir plus sur toi " +
        (props.isDeliverer
          ? 'pour que tu puisses nous rejoindre'
          : 'pour que nous puissions te livrer ton kit')
    )
    .pauseFor(300)
    .callFunction(() => step.value++)
    .start()
})

async function startStepShowStep2() {
  if (!address.value) {
    errors.value.address = "Merci d'indiquer votre adresse"
    return
  }

  if (!props.isDeliverer) {
    try {
      const response = await axios.post(import.meta.env.VITE_APP_BASE_URL + '/sector/is-open', {
        lat: address.value?.location?.latitude,
        long: address.value?.location?.longitude,
        completionUrl: address.value?.completionUrl,
        gouvID: address.value?.gouvID
      })

      if (!response.data.sector) {
        errors.value.address = 'Désolé, nous ne livrons pas encore dans votre ville'
        return
      } else if (response.data?.sector?.pause || response.data?.sector?.parentSector?.pause) {
        errors.value.address = 'Désolé, nous sommes actuellement en pause dans votre ville'
        return
      }
    } catch (error: any) {
      errors.value.address = error.response?.data?.message || 'Désolé, erreur serveur (adresse)'
      console.error(error)
      return
    }
  }

  dataForm.value!.address = address.value

  showFormStep1.value = false

  step.value++
}

async function validateForm() {
  if (props.isDeliverer) {
    try {
      await string().required().validate(sector.value?._id)
    } catch (err: any) {
      errors.value.sector = err.errors[0]

      return
    }

    dataForm.value!.sector = sector.value?._id
  } else {
    try {
      await yupSchemas.schoolName.validate(schoolName.value)
    } catch (err: any) {
      errors.value.schoolName = err.errors[0]

      return
    }

    dataForm.value!.schoolName = schoolName.value
  }

  showFormStep2.value = false

  const store = useUserForm()

  store.updateAddressAndCityForm(dataForm.value)

  if (props.onFormFinish) props.onFormFinish()
}

async function searchPlace(addr: string) {
  if (autoCompleteTimeout.value) clearTimeout(autoCompleteTimeout.value)

  // if (moment().diff(localStorage.getItem('addressAutocompleteLastUpdate'), 'hours') > 12) {
  //   localStorage.removeItem('addressAutocompleteEnable')
  //   localStorage.removeItem('addressAutocompleteLastUpdate')
  // }

  // if (localStorage.getItem('addressAutocompleteEnable') === 'false') {
  //   errors.value.address = "Désolé, l'auto-complétion des adresses est désactivée pour aujourd'hui"
  //   return
  // }

  // if (nbRequest.value > 20) {
  //   errors.value.address =
  //     "Désolé, vous avez atteint le nombre maximum de requêtes pour aujourd'hui"
  //   localStorage.setItem('addressAutocompleteEnable', 'false')
  //   localStorage.setItem('addressAutocompleteLastUpdate', new Date().toISOString())
  //   return
  // }

  autoCompleteTimeout.value = setTimeout(async () => {
    nbRequest.value += 1

    try {
      const response = await axios.get(
        import.meta.env.VITE_APP_BASE_URL + '/mapkit/searchAutocomplete?q=' + encodeURI(addr)
      )

      if (response.data.failed || localStorage.getItem('forceGouvAutoComplete') === 'true') {
        autocompleteGouv(addr)
      } else {
        autoCompletePlaces.value = response.data.results
      }
    } catch (error) {
      console.error(error)
      //this.autoCompleteAddressEnable = false;
    }
  }, 1500)
}

async function autocompleteGouv(addr: string) {
  try {
    const response = await axios.get(
      'https://api-adresse.data.gouv.fr/search/?q=' + encodeURI(addr)
    )

    if (response.data.features.length === 0) {
      errors.value.address = 'Adresse non trouvée'
      return
    }

    autoCompletePlaces.value = response.data.features.map((feature: any) => ({
      location: {
        latitude: feature.geometry.coordinates[1],
        longitude: feature.geometry.coordinates[0]
      },
      displayLines: [feature.properties.label],
      completionUrl: 'https://api-adresse.data.gouv.fr/search/?q=' + encodeURI(addr),
      gouvID: feature.properties.id
    }))
  } catch (error) {
    errors.value.address =
      'Notre serveur a rencontré un problème suite a une forte demande, si le problème persiste, veuillez réessayer dans 12H ou contacter le support.'
    isLoading.value = false
  }
}
</script>
