<template>
    <div class="my-3">
        <span class="isolate inline-flex rounded-md shadow-sm w-full m-auto mb-10 justify-between gap-4">
            <button :disabled="step <= 0" @click="step > 0 ? step-- : null" type="button"
                class="font-bold text-sm relative inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:bg-blue-400 hover:ring-blue-400 focus:z-10">
                <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
                <span>Précédent</span>
            </button>
            <button :disabled="step < 0" @click="step >= 0 ? skipAnimation() : null" type="button"
                class="font-bold text-sm relative -ml-px inline-flex items-center rounded-md disabled:bg-transparent bg-blue-600 px-2 py-2 text-white ring-1 ring-inset disabled:ring-gray-300 ring-blue-600 hover:ring-blue-400 hover:bg-blue-400  focus:z-10">
                <span>Passer l'animation</span>
                <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
            </button>
        </span>

        <div class="text-center text-lg sm:text-3xl" style="font-family: 'Simple Plan'">
            <div id="animated-text"></div>
        </div>

        <TransitionRoot appear :show="showFormStep1" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto grid grid-cols-1 sm:grid-cols-2 gap-2 mt-3 justify-center">
            <div :class="['px-4 py-2 bg-gray-100 text-black rounded col-span-1 mx-10', meat ? 'border-green-600 border-solid border-2 bg-green-600 text-white' : '']"
                @click="meat = !meat">
                <p class="text-center font-bold">Viande</p>
                <p class="text-center">Plat, conserve composé de viande</p>
            </div>

            <div :class="['px-4 py-2 bg-gray-100 text-black rounded mx-10', !glutenFree ? 'border-green-600 border-solid border-2 bg-green-600 text-white' : '']"
                @click="glutenFree = !glutenFree">
                <p class="text-center font-bold">Gluten</p>
                <p class="text-center">Plat, conserve contenant du gluten</p>
            </div>

            <div :class="['px-4 py-2 bg-gray-100 text-black rounded mx-10', fish ? 'border-green-600 border-solid border-2 bg-green-600 text-white' : '']"
                @click="fish = !fish">
                <p class="text-center font-bold">Poisson</p>
                <p class="text-center">Plat, conserve contenant du poisson</p>
            </div>

            <div :class="['px-4 py-2 bg-gray-100 text-black rounded mx-10', porc ? 'border-green-600 border-solid border-2 bg-green-600 text-white' : '']"
                @click="porc = !porc">
                <p class="text-center font-bold">Porc</p>
                <p class="text-center">Plat, conserve contenant du porc</p>
            </div>

            <button type="button" @click="startStepShowStep2"
                class="mx-10 sm:col-span-2 my-auto inline-flex items-center gap-x-1.5 rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                <CheckCircleIcon class="ml-auto h-5 w-5" aria-hidden="true" />
                <span class="text-center mr-auto">Valider</span>
            </button>
        </TransitionRoot>

        <TransitionRoot appear :show="showFormStep2" enter="transition-opacity duration-1000" enter-from="opacity-0"
            enter-to="opacity-100" leave="transition-opacity duration-150" leave-from="opacity-100" leave-to="opacity-0"
            class="mx-3 m-auto flex gap-2 mt-3 justify-center">
            <RadioGroup v-model="periodicProtection">
                <RadioGroupLabel class="sr-only">Periodic protection</RadioGroupLabel>
                <div class="space-y-2">
                    <RadioGroupOption @click="validateForm" as="template" v-for="type in ['OUI', 'NON']" :key="type"
                        :value="type === 'OUI'" v-slot="{ active, checked }">
                        <div :class="[
                active
                    ? 'ring-2 ring-white/60 ring-offset-2 ring-offset-sky-300'
                    : '',
                checked ? 'bg-blue-600 text-white ' : 'bg-white ',
            ]" class="relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none">
                            <div class="w-full items-center justify-between">
                                <div class="items-center">
                                    <div class="text-sm">
                                        <RadioGroupLabel as="p"
                                            :class="[checked ? 'text-white' : 'text-gray-900', 'text-center m-auto']"
                                            class="font-medium">
                                            {{ type }}
                                        </RadioGroupLabel>
                                        <RadioGroupDescription as="p" v-if="type === 'OUI'"
                                            :class="['text-center', checked ? 'text-white' : 'text-gray-900']">
                                            Tampon/Serviette etc...
                                        </RadioGroupDescription>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </RadioGroupOption>
                </div>
            </RadioGroup>
        </TransitionRoot>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, type Ref } from 'vue';
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core';
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption, TransitionRoot } from '@headlessui/vue';
import { CheckCircleIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/16/solid';
import GraphemeSplitter from "grapheme-splitter";
import { useUserForm } from '@/stores/userForm';

const props = defineProps({
    onFormFinish: {
        type: Function,
    }
})

const dataForm = defineModel<{
    porc: boolean;
    meat: boolean;
    fish: boolean;
    glutenFree: boolean;
    periodicProtection: boolean;
} | any>();
dataForm.value = {}

const typewriter = ref();
const meat = ref(!!dataForm.value?.meat);
const porc = ref(!!dataForm.value?.porc);
const fish = ref(!!dataForm.value?.fish);
const glutenFree = ref(!!dataForm.value?.glutenFree);
const periodicProtection = ref(!!dataForm.value?.periodicProtection);


const showFormStep1 = ref(false);
const showFormStep2 = ref(false);

const step = ref(-1);
const phrase = ref<string>();
let refToShow: Ref<Boolean>;

function skipAnimation() {
    refToShow.value = true;
}

watch(() => step.value, (newValue, oldValue) => {
    showFormStep1.value = false;
    showFormStep2.value = false;

    switch (newValue) {
        case 0:
            refToShow = showFormStep1;
            phrase.value = '<div><div />Nous avons maintenant besoin de savoir si tu as des restrictions alimentaires à nous faire part, il te suffit de sélectionner dans la liste ci-dessous ce que tu acceptes d\'avoir dans ton kit'
            break;
        case 1:
            refToShow = showFormStep2;
            phrase.value = `<div><div />C'est noté ! Souhaite-tu que ton kit contienne des protections hygiénique si notre stock le permet ? &#129656;`
            break;
        default:
            phrase.value = ''
            break;
    }

    ((newValue > 0 || (newValue === 0 && oldValue >= 1)) ? typewriter.value.deleteAll(-1000) : typewriter.value)
        .typeString(phrase.value)
        .callFunction(() => {
            refToShow.value = true
        })
        .start();
})

const stringSplitter = (string: string) => {
    const splitter = new GraphemeSplitter();
    return splitter.splitGraphemes(string);
};

onMounted(() => {
    typewriter.value = new Typewriter('#animated-text', { delay: 30, stringSplitter });

    step.value++
})

function startStepShowStep2() {
    dataForm.value!.meat = meat.value;
    dataForm.value!.porc = porc.value;
    dataForm.value!.fish = fish.value;
    dataForm.value!.glutenFree = glutenFree.value;

    showFormStep1.value = false;

    step.value++
}

function validateForm() {
    dataForm.value!.periodicProtection = periodicProtection.value;

    showFormStep2.value = false;

    const store = useUserForm();

    store.updateMoreAboutYourKitForm(dataForm.value);

    if (props.onFormFinish)
        props.onFormFinish()
}

</script>