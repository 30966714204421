import { ref } from 'vue'
import { defineStore } from 'pinia'
import moment, { type Moment } from 'moment'

export const useUserForm = defineStore(
  'userForm',
  () => {
    const currentStep = ref(0)
    const startedDate = ref<Moment>(moment())

    const DelivererOrStudentForm = ref<{
      firstName: string
      lastName: string
      gender: number
      username: string
      password: string
      applyType: 'DELIVERER' | 'STUDENT'
    }>()

    const updateDelivererOrStudentForm = (data: typeof DelivererOrStudentForm.value) => {
      DelivererOrStudentForm.value = data
      currentStep.value = 1
    }

    const AddressAndCityForm = ref<{
      address: {
        location: {
          latitude: number
          longitude: number
        }
        displayLines: string[]
        completionUrl: string
      }
      schoolName?: string
      sector?: string
    }>()

    const updateAddressAndCityForm = (data: typeof AddressAndCityForm.value) => {
      AddressAndCityForm.value = data
      currentStep.value = 2
    }

    const MoreInfosAboutYouForm = ref<{
      email: string
      phone: string
      birthDate: number
      newsletter: boolean
    }>()

    const updateMoreInfosAboutYouForm = (data: typeof MoreInfosAboutYouForm.value) => {
      MoreInfosAboutYouForm.value = data
      currentStep.value = 3
    }

    const YourProfileForm = ref<{
      biography: string
      avatar: string
      imageRight: boolean
      wantToBeStaff: boolean
      meansOfTransportation: string
    }>()

    const updateYourProfileForm = (data: typeof YourProfileForm.value) => {
      YourProfileForm.value = data
      currentStep.value = 4
    }

    const MoreAboutYourKitForm = ref<{
      porc: boolean
      meat: boolean
      fish: boolean
      glutenFree: boolean
      periodicProtection: boolean
    }>()

    const updateMoreAboutYourKitForm = (data: typeof MoreAboutYourKitForm.value) => {
      MoreAboutYourKitForm.value = data
      currentStep.value = 5
    }

    const LegalStuffForm = ref<{
      sign: string
      cniRecto: string
      cniVerso: string
      selfie: string
    }>()

    const updateLegalStuffForm = (data: typeof LegalStuffForm.value) => {
      LegalStuffForm.value = data
      currentStep.value = 6
    }

    const MoreAboutYourLife = ref<{
      scolarship?: string
      rentReceipt?: string
      handicapProof?: string
      bourseProof?: string
      gazInvoice?: string
      elecInvoice?: string
      waterInvoice?: string
      revenueProof?: string
      aplProof?: string
      otherProof?: string
      revenueAmount?: number
      waterAmount?: number
      elecAmount?: number
      gazAmount?: number
      aplAmount?: number
      bourseGrade?: number
      rentAmount?: number
      rentType?: number
      otherAmount?: number
      otherComment?: string
    }>()

    const updateMoreAboutYourLife = (data: typeof MoreAboutYourLife.value) => {
      MoreAboutYourLife.value = data
      currentStep.value = 7
    }

    return {
      AddressAndCityForm,
      LegalStuffForm,
      MoreAboutYourKitForm,
      YourProfileForm,
      MoreInfosAboutYouForm,
      DelivererOrStudentForm,
      MoreAboutYourLife,
      updateAddressAndCityForm,
      updateDelivererOrStudentForm,
      updateLegalStuffForm,
      updateMoreAboutYourKitForm,
      updateMoreInfosAboutYouForm,
      updateYourProfileForm,
      updateMoreAboutYourLife,
      startedDate,
      currentStep
    }
  },
  { persist: true }
)
