<script setup lang="ts">
import DelivererOrStudent from './components/steps/DelivererOrStudent.vue'
import AddressAndCity from './components/steps/AddressAndCity.vue'
import { computed, onMounted, ref } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import YourProfile from './components/steps/YourProfile.vue'
import MoreAboutYourKit from './components/steps/MoreAboutYourKit.vue'
import LegalStuff from './components/steps/LegalStuff.vue'
import { useUserForm } from './stores/userForm'
import { ExclamationCircleIcon } from '@heroicons/vue/16/solid'
import axios from 'axios'
import GraphemeSplitter from 'grapheme-splitter'
// @ts-ignore
import Typewriter from 'typewriter-effect/dist/core'
import MoreInfosAboutYou from './components/steps/MoreInfosAboutYou.vue'
import MoreAboutYourLife from './components/steps/MoreAboutYourLife.vue'
import moment from 'moment'
import { useReCaptcha } from 'vue-recaptcha-v3'

const step = ref(0)
const delivererOrStudentForm = ref<{
  firstName: string
  lastName: string
  gender: number
  username: string
  password: string
  applyType: 'DELIVERER' | 'STUDENT'
}>()

const AddressAndCityForm = ref<{
  address: {
    location: {
      latitude: number
      longitude: number
    }
    displayLines: string[]
    completionUrl: string
  }
  schoolName?: string
  sector?: string
}>()

const MoreInfosAboutYouForm = ref<{
  email: string
  phone: string
  birthDate: number
  newsletter: boolean
}>()

const YourProfileForm = ref<{
  biography: string
  avatar: string
  imageRight: boolean
  wantToBeStaff: boolean
  meansOfTransportation: string
}>()

const MoreAboutYourKitForm = ref<{
  porc: boolean
  meat: boolean
  fish: boolean
  glutenFree: boolean
  periodicProtection: boolean
}>()

const LegalStuffForm = ref<{
  sign: string
  cniRecto: string
  cniVerso: string
  selfie: string
}>()

const MoreAboutYourLifeForm = ref<{
  sign: string
  cniRecto: string
  cniVerso: string
  selfie: string
  scholarship: string
  rentReceipt: string
  handicapProof: string
  schoolProof: string
}>()

const userForm = useUserForm()
const open = ref(false)

const isDeliverer = computed(() => delivererOrStudentForm.value?.applyType === 'DELIVERER')

function showNextStep() {
  if (step.value === 2 && !isDeliverer.value) step.value += 2
  else if (step.value === 3 && isDeliverer.value) step.value += 3
  else {
    step.value += 1
  }

  userForm.currentStep = step.value
}

onMounted(() => {
  if (
    userForm.currentStep > 0 &&
    userForm.startedDate &&
    moment().diff(moment(userForm.startedDate), 'hours') <= 2
  ) {
    //open.value = true
    userForm.currentStep = 0
  } else {
    //userForm.$reset()
    userForm.startedDate = moment()
    userForm.currentStep = 0
    userForm.DelivererOrStudentForm = undefined
    userForm.LegalStuffForm = undefined
    userForm.MoreAboutYourKitForm = undefined
    userForm.YourProfileForm = undefined
    userForm.MoreInfosAboutYouForm = undefined
    userForm.AddressAndCityForm = undefined
    userForm.MoreAboutYourLife = undefined
  }
})

function setupSaveSession() {
  // delivererOrStudentForm.value = userForm.DelivererOrStudentForm;
  // LegalStuffForm.value = userForm.LegalStuffForm;
  // MoreAboutYourKitForm.value = userForm.MoreAboutYourKitForm;
  // YourProfileForm.value = userForm.YourProfileForm;
  // MoreInfosAboutYouForm.value = userForm.MoreInfosAboutYouForm;
  // AddressAndCityForm.value = userForm.AddressAndCityForm;
  // MoreAboutYourLife.value = userForm.MoreAboutYourLife;
  // step.value = userForm.currentStep;
  // open.value = false;
}

const stringSplitter = (string: string) => {
  const splitter = new GraphemeSplitter()
  return splitter.splitGraphemes(string)
}

const successBtn = ref(false)
const errorBtn = ref(false)
const reCaptcha = useReCaptcha()

async function submitData() {
  const typewriter = new Typewriter('#animated-text', { delay: 25, stringSplitter })

  typewriter
    .typeString('Nous envoyons vos données sur notre serveur... 💻')
    .callFunction(async () => {
      try {
        const recaptcha = async () => {
          await reCaptcha?.recaptchaLoaded()
          const token = await reCaptcha?.executeRecaptcha('signup')

          return token
        }

        const token = await recaptcha()

        await axios.post(import.meta.env.VITE_APP_BASE_URL + '/register', {
          ...delivererOrStudentForm.value,
          ...LegalStuffForm.value,
          ...MoreAboutYourKitForm.value,
          ...YourProfileForm.value,
          ...MoreInfosAboutYouForm.value,
          ...AddressAndCityForm.value,
          ...MoreAboutYourLifeForm.value,
          'g-captcha-token': token
        })

        userForm.startedDate = moment()
        userForm.currentStep = 0
        userForm.DelivererOrStudentForm = undefined
        userForm.LegalStuffForm = undefined
        userForm.MoreAboutYourKitForm = undefined
        userForm.YourProfileForm = undefined
        userForm.MoreInfosAboutYouForm = undefined
        userForm.AddressAndCityForm = undefined
        userForm.MoreAboutYourLife = undefined

        typewriter
          .typeString(
            `<br/>Nous avons bien reçu votre inscription. Votre compte doit faire l'objet d'une vérification avant d'être validé. En attendant, vous pouvez valider votre email et vous recevrez un email quand votre compte sera prêt ❤️`
          )
          .callFunction(() => {
            successBtn.value = true
            errorBtn.value = false
          })
          .start()
      } catch (error: any) {
        typewriter
          .typeString(
            "<br/>Une erreur est survenue l'ors de l'envoie des données: " +
              error.response?.data?.message || error.message
          )
          .callFunction(() => {
            successBtn.value = false
            errorBtn.value = true
          })
          .start()
      }
    })
    .start()
}
</script>

<template>
  <main>
    <div class="text-center w-full text-lg mb-4">Étape {{ step + 1 }}/8</div>

    <div class="text-center text-lg sm:text-3xl" style="font-family: 'Simple Plan'">
      <div id="animated-text-finish"></div>
    </div>

    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="relative z-10">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div class="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div
            class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0"
          >
            <TransitionChild
              as="template"
              enter="ease-out duration-300"
              enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enter-to="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leave-from="opacity-100 translate-y-0 sm:scale-100"
              leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"
              >
                <div>
                  <div
                    class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
                  >
                    <ExclamationCircleIcon class="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div class="mt-3 text-center sm:mt-5">
                    <DialogTitle as="h3" class="text-base font-semibold leading-6 text-gray-900"
                      >Une session est deja en cours</DialogTitle
                    >
                    <div class="mt-2">
                      <p class="text-sm text-gray-500">
                        Vous avez deja une session d'incription en cours. Souhaitez vous la
                        continuer ?
                      </p>
                    </div>
                  </div>
                </div>
                <div class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    class="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    @click="setupSaveSession"
                  >
                    Continuer
                  </button>
                  <button
                    type="button"
                    class="mt-3 inline-flex w-full justify-center rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-red-400 hover:ring-red-300 hover:bg-red-300 sm:col-start-1 sm:mt-0"
                    ref="cancelButtonRef"
                    @click="
                      () => {
                        userForm.$reset
                        open = false
                      }
                    "
                  >
                    Re-commencer
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>

    <TransitionRoot
      appear
      v-if="step === 0"
      :show="step === 0"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <DelivererOrStudent
        v-model="delivererOrStudentForm"
        :on-form-finish="showNextStep"
      ></DelivererOrStudent>
    </TransitionRoot>

    <TransitionRoot
      appear
      v-if="step === 1"
      :show="step === 1"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <AddressAndCity
        v-model="AddressAndCityForm"
        :is-deliverer="isDeliverer"
        :on-form-finish="showNextStep"
      >
      </AddressAndCity>
    </TransitionRoot>

    <TransitionRoot
      appear
      v-if="step === 2"
      :show="step === 2"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <MoreInfosAboutYou
        v-model="MoreInfosAboutYouForm"
        :on-form-finish="showNextStep"
      ></MoreInfosAboutYou>
    </TransitionRoot>

    <TransitionRoot
      appear
      v-if="step === 3"
      :show="step === 3"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <YourProfile v-model="YourProfileForm" :on-form-finish="showNextStep"></YourProfile>
    </TransitionRoot>

    <TransitionRoot
      appear
      v-if="step === 4"
      :show="step === 4"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <MoreAboutYourLife
        v-model="MoreAboutYourLifeForm"
        :on-form-finish="showNextStep"
      ></MoreAboutYourLife>
    </TransitionRoot>

    <TransitionRoot
      appear
      v-if="step === 5"
      :show="step === 5"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <MoreAboutYourKit
        v-model="MoreAboutYourKitForm"
        :on-form-finish="showNextStep"
      ></MoreAboutYourKit>
    </TransitionRoot>

    <TransitionRoot
      appear
      v-if="step === 6"
      :show="step === 6"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <LegalStuff v-model="LegalStuffForm" :is-deliverer="isDeliverer" :on-form-finish="submitData">
      </LegalStuff>
    </TransitionRoot>

    <TransitionRoot
      appear
      v-if="successBtn"
      :show="successBtn"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <button
        type="button"
        onclick="document.location = 'https://download.delivraide.org/'"
        class="rounded-md bg-indigo-50 px-3.5 py-2.5 text-sm font-semibold text-indigo-600 shadow-sm hover:bg-indigo-100"
      >
        Telecharger l'application Delivraide
      </button>
    </TransitionRoot>

    <TransitionRoot
      appear
      v-if="errorBtn"
      :show="errorBtn"
      enter="transition-opacity duration-1000"
      enter-from="opacity-0"
      enter-to="opacity-100"
      leave="transition-opacity duration-150"
      leave-from="opacity-100"
      leave-to="opacity-0"
      class="mx-3 m-auto flex gap-2 mt-3 justify-center"
    >
      <button
        type="button"
        @click="submitData"
        class="rounded-md bg-red-50 px-3.5 py-2.5 text-sm font-semibold text-red-600 shadow-sm hover:bg-red-100"
      >
        Réessayer
      </button>
    </TransitionRoot>
  </main>
</template>

<style lang="scss">
@mixin sp-layout {
  @media screen and (max-width: 750px) {
    @content;
  }
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: radial-gradient(ellipse at bottom, #0d1d31 0%, #0c0d13 100%);
  overflow: hidden;
}

@function random_range($min, $max) {
  $rand: random();
  $random_range: $min + floor($rand * (($max - $min) + 1));
  @return $random_range;
}

.stars {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  transform: rotate(-45deg);
}

.star {
  $star-count: 50;
  --star-color: var(--primary-color);
  --star-tail-length: 6em;
  --star-tail-height: 2px;
  --star-width: calc(var(--star-tail-length) / 6);
  --fall-duration: 9s;
  --tail-fade-duration: var(--fall-duration);

  position: absolute;
  top: var(--top-offset);
  left: 0;
  width: var(--star-tail-length);
  height: var(--star-tail-height);
  color: var(--star-color);
  background: linear-gradient(45deg, currentColor, transparent);
  border-radius: 50%;
  filter: drop-shadow(0 0 6px currentColor);
  transform: translate3d(104em, 0, 0);
  animation:
    fall var(--fall-duration) var(--fall-delay) linear infinite,
    tail-fade var(--tail-fade-duration) var(--fall-delay) ease-out infinite;

  @include sp-layout {
    // For mobile performance, tail-fade animation will be removed QAQ
    animation: fall var(--fall-duration) var(--fall-delay) linear infinite;
  }

  @for $i from 1 through $star-count {
    &:nth-child(#{$i}) {
      --star-tail-length: #{calc(random_range(500em, 750em) / 100)};
      --top-offset: #{calc(random_range(0vh, 10000vh) / 100)};
      --fall-duration: #{calc(random_range(6000, 12000s) / 1000)};
      --fall-delay: #{calc(random_range(0, 10000s) / 1000)};
    }
  }

  &::before,
  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: calc(var(--star-width) / -2);
    width: var(--star-width);
    height: 100%;
    background: linear-gradient(45deg, transparent, currentColor, transparent);
    border-radius: inherit;
    animation: blink 2s linear infinite;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

@keyframes fall {
  to {
    transform: translate3d(-30em, 0, 0);
  }
}

@keyframes tail-fade {
  0%,
  50% {
    width: var(--star-tail-length);
    opacity: 1;
  }

  70%,
  80% {
    width: 0;
    opacity: 0.4;
  }

  100% {
    width: 0;
    opacity: 0;
  }
}

@keyframes blink {
  50% {
    opacity: 0.6;
  }
}
</style>
